<template>
  <PageDetails>
    <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
      Edit Venue {{ isLoading ? "-" : "-" }}
      <span v-if="venue">{{venue.name}}</span>
    </h1>
    <div class="lg:max-w-5xl">
      <VenueTabs :venue-id="venueId" current-route="manage-cards">
        <template v-slot:right-cta>
          <div @click="addNewCard()" class="bg-gp_pink-default-500 hover:bg-gp_pink-default-400 flex items-center px-3 py-1 md:py-2 rounded-lg text-white space-x-2 cursor-pointer">
            <CreditCardIcon />
            <span class="text-sm md:text-md">Add new card</span>
          </div>
        </template>
      </VenueTabs>

      <template v-if="isLoading">
        <div class="p-8 flex justify-center items-start">
          <PageSpinner
            tailwind-diameter="8"
            tailwind-border="4"
            tailwind-color="pink-500"
          />
        </div>
      </template>

      <template v-if="!isLoading && paymentCards.length">
          <div v-for="(card, index) in paymentCards" :key="card.id" class="mt-10 max-w-7xl">
                <div class="grid grid-cols-2 lg:grid-cols-6 bg-gray-100 px-4 py-2 rounded-t-lg font-light text-sm border">
                  <div class="hidden lg:block">Card Nickname</div>
                  <div class="hidden lg:block">Expiry</div>
                  <div class="hidden lg:block">Additional information</div>
                </div>

                <div class="grid grid-cols-2 lg:grid-cols-6 bg-none px-4 py-4 rounded-b-lg font-light text-sm border items-center">
                  <div>
                    <span class="font-bold block lg:hidden">Card Nickname:</span>
                    <p class="">{{ card.alias }}</p>
                  </div>
                  <div>
                    <span v-show="card.expiry_month && card.expiry_year" class="font-bold block lg:hidden">Expiry:</span>
                    <p v-show="card.expiry_month && card.expiry_year" class="">{{ card.expiry_month }}/{{ card.expiry_year }}</p>
                  </div>
                  <div>
                    <p class="font-bold">**** {{ card.last_4_digits}}</p>
                  </div>
                  <div class="space-y-3 mt-4 lg:mt-0">
                    <div class="flex items-center space-x-2">
                      <span class="font-bold">Added</span>
                      <span class="">{{ formattedDate(card.created_at) }}</span>
                    </div>
                    <div>
                      <span class="font-bold">Added by</span>
                      <p class="">{{ card.email }}</p>
                    </div>
                  </div>
                  <div class="ml-0 mt-4 lg:ml-16 lg:mt-0">
                    <div v-show="index == 0" class="px-5 py-1 text-xs text-white bg-[#0D1C43] rounded-md flex items-center space-x-3 uppercase font-bold mt-3 w-44">
                      <CreditCardIcon stroke="white" class="w-5 h-6"/>
                      <span>Active Card</span>
                    </div>
                    <div v-show="index == 0 && cardExpiryCheck(card)" class="px-5 py-1 text-xs text-white bg-red-500 rounded-md flex items-center space-x-3 uppercase font-bold mt-3 w-44">
                      <CreditCardIcon stroke="white" class="w-5 h-6"/>
                      <span>Expires soon</span>
                    </div>
                    <div v-show="index !== 0" class="px-5 py-1 text-xs text-white bg-gray-600 rounded-md flex items-center space-x-3 uppercase font-bold mt-3 w-44">
                      <CreditCardIcon stroke="white" class="w-5 h-6"/>
                      <span>Inactive card</span>
                    </div>
                  </div>
                </div>
          </div>
      </template>
      <template v-if="!isLoading && !paymentCards.length">
        <div class="p-8 mt-32 flex justify-center items-start">
          <span>No payment cards have been added!</span>
        </div>
      </template>
      <SaveCard ref="savecardmodal" />
    </div>
  </PageDetails>
</template>

<script>
import PageDetails from "@/components/ui/wrappers/PageDetails.vue";
import VenueTabs from "../../components/venues/VenueTabs.vue";
import { CreditCardIcon } from "@vue-hero-icons/outline"
import { paymentsMethods } from "@/state/helpers";
import NProgress from "nprogress";
import SaveCard from '../../components/modal/venues/subscriptions/SaveCard.vue'

export default {
  components: {
    VenueTabs,
    PageDetails,
    CreditCardIcon,
    SaveCard
  },
  props: ['venueId'],
  data() {
    return {
      venue_id: this.venueId,
      paymentCards: [],
      isLoading: false,
    };
  },
  computed: {
    venue() {
      return this.$store.getters['venue/currentVenue']
    },
  },
  methods: {  
    ...paymentsMethods,
    formattedDate(unformatted_date) {
      const date = new Date(unformatted_date);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    cardExpiryCheck(card) {
      const currentDate = new Date();  
      const expiryDate = new Date(parseInt(card.expiry_year), parseInt(card.expiry_month), 0);
      const diffInTime = expiryDate.getTime() - currentDate.getTime();
      const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

      return diffInDays > 0 && diffInDays < 60 ? true : false;
    },
    addNewCard() {
      this.$refs.savecardmodal.addVenueInfo({ id: this.venue.id , name: this.venue.name });
    }
  },
  mounted() {

    NProgress.start();
    this.isLoading = true;

    this.getVenuePaymentCards({ venue_id: parseInt(this.venue_id) })
      .then((response) => {
        this.paymentCards = response;
      })
      .finally(() => {
        this.isLoading = false;
        NProgress.done();
      });
  }
};
</script>
