<template>
  <div class="details-view"> 

		<template v-if="isLoading">
			<div class="p-8 flex justify-center items-start">
				<PageSpinner 
					tailwind-diameter="8"
					tailwind-border="4"
					tailwind-color="pink-500"
				/>
			</div>
		</template>
		<template v-else-if="!isLoading && !hasData">
			<NoDataMsgWrapper>Sorry, we could not load the necessary data needed to create a venue</NoDataMsgWrapper>
		</template>
		<template v-else>
<div class="lg:max-w-5xl">
        <form class="mb-24 form input-group" ref="form" novalidate> 
            <div class="mt-6 sm:mt-5 col-span-full">
              <h4 class="text-lg leading-6 font-medium text-gray-900">
                Profile
              </h4>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                This information will be shown on your venue profile page to
                potential artists.
              </p>
            </div>

            <Accordian 
            heading="Copy Venue"
            :toggle="true" v-if="userVenues && userVenues.length">
            >
                        <template #contents>
                          <p class="col-span-full my-4">By selecting a venue, you can choose to copy it's team permissions and billing address to the new venue. If the venue's subscription type supports unlimited gigs, the subscription will also be copied to the new venue.</p>
                          <TRichSelect
                            id="userVenues"
                            name="userVenues"
                            v-model="form.venueId"
                            :options="userVenues"
                            @change="selectVenueToCopy"
                            value-attribute="id"
                            text-attribute="name"
                            :placeholder="'Select a venue to copy'"
                            class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                        </template>
                    </Accordian>

            <div class="mt-6 sm:mt-5  col-span-full space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="venue-name"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Venue name
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                  
                       <InputText required id="venue-name" v-on:input="form.name = $event;" v-bind:value="form.name"  hint="The venue name must be populated"
                       error="The venue name must be completed" name="venue-name"
                        />

                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="about"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  About
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                  <input-text-area required aria-required="required" v-on:input="form.about = $event" v-bind:value="form.about" name="about" ref="about" id="about"  rows="10"  />
                   
 
                  <p class="mt-2 text-sm text-gray-500">
                    Write a few sentences about your venue.
                  </p>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Tags
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <TRichSelect 
                    id="tag-selection"
                    ref="tagpicker"
                    name="tag-selection"
                    v-model="form.venueTags"
                    @change="pickTag" 
                    :options="venueTags"
                    value-attribute="id" 
                    text-attribute="name" 
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />

                  <input-tags ref="tagoutput" class="grid-cols-none grid col-span-full grid-flow-col gap-4 items-start justify-start py-4" />
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Venue location
                </label>
                <div class="mt-4 sm:mt-0 sm:col-span-2 flex items-center">
                  <Button v-show="this.location.length == 0"
                          @click.native="$refs.locationModal.toggleModal();"
                          type="button"
                          class="sm:mt-2">Add venue location</Button>
                  <input
                    v-show="this.location.length != 0"
                    type="text"
                    name="venue-location"
                    id="venue-location"

                    disabled
                    v-model="location.preview"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                  <div v-show="this.location.length != 0" class="ml-4 sm:mt-px sm:pt-2">
                    <TrashIcon @click="removeLocation()" class="cursor-pointer text-gp_pink-default-500" />
                  </div>
                </div>
              </div>

              <div
                class="sm:grid input-group sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="photo"
                  class="block text-sm font-medium text-gray-700"
                >
                  Venue Logo
                </label>

								<div class="input-group mt-1 sm:mt-0 sm:col-span-2">
									<profile-picture-upload 
										:image="icon" 
										@onImageChange="profilePictureUploaded" 
										profile-type="VenueProfilePicture"
									/>
                </div>

              </div> 

              <div class="sm:grid input-group sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
								<div>
									<h4 class="text-sm leading-6 font-bold text-gray-900">
										Gallery
									</h4>
									<p class="mt-1 max-w-2xl text-sm text-gray-500">
										Upload photos for your artist profile page (4mb limit, jpg or png).
									</p>
								</div>
								
								<div class="mt-1 sm:mt-0 sm:col-span-2">
									<upload-gallery 
                    :images="gallery"
										gallery-type="VenueGallery"
                    @onGalleryChange="onGalleryChanged" 
                  />
								</div>

              </div>
            </div>

            <!-- Profile Contact Details -->
            <div class="mt-6 sm:mt-5  col-span-full">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Profile Contact Details
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                This information will be shown on your venue profile page to
                potential artists.
              </p>
            </div>

            <div class="mt-6 sm:mt-5 space-y-6  col-span-full sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <span>Venue address</span>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label
                  for="venue-address-line1"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Line 1
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                  
                  
                       <InputText required id="venue-address-line1" v-on:input="form.address.address_line_1 = $event;" v-bind:value="form.address.address_line_1"
                       error="The venue address line one must be provided" name="venue-address-line1" class="max-w-lg sm:max-w-xs"
                        />  
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-line2"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Line 2 (Optional)
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="venue-address-line2"
                    id="venue-address-line2"
                    v-model="form.address.address_line_2"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-town"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Town/City
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                  
                       <InputText required id="venue-address-town" v-on:input="form.address.town = $event;" v-bind:value="form.address.town"
                       error="The venue town must be provided" name="venue-address-town" class="max-w-lg sm:max-w-xs"
                        /> 
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-county"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  County
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2"> 

                  
                       <InputText required id="venue-address-county" v-on:input="form.address.county = $event;" v-bind:value="form.address.county" 
                       error="The venue county must be provided" name="venue-address-county" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
                <div
                        class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
                >
                    <label
                            for="countries"
                            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                        Country
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2 pt-2">
                    <TRichSelect
                                id="countries"
                                ref="countriespicker"
                                name="countries"
                                v-model="form.address.country_id"
                                @change="pickCountry"
                                :options="countries"
                                value-attribute="id"
                                text-attribute="name"
                                class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        />

                    </div>
                </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Postcode
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  
                       <InputText required id="venue-address-postcode" v-on:input="form.address.post_code = $event;" v-bind:value="form.address.post_code"  
                       error="The venue postcode must be provided" name="venue-address-postcode" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputText required id="email" v-on:input="form.email = $event;" v-bind:value="form.email"  type="email" hint="A primary contact email must be provided"
                       error="A primary contact email must be provided" name="email"
                        />
 
                  <p class="mt-2 text-sm text-gray-500">
                    The primary venue email address.
                  </p>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="tel"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Telephone
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                  <InputText required id="tel" v-on:input="form.telephone = $event;" v-bind:value="form.telephone"  name="tel"  type="tel"
                    hint="A telephone number must be provided" error="A telephone number must be provided"/> 
                  <p class="mt-2 text-sm text-gray-500">
                    The primary venue telephone number.
                  </p>
                </div>
              </div>
              <div
                class="input-group sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="website"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Website
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                                  
                       <InputText  id="website" v-on:input="form.website = $event;" v-bind:value="form.website" 
                        name="website"   type="url"
                        />
  
                  <p class="mt-2 text-sm text-gray-500">
                    The primary venue website.  
                  </p>
                </div>
              </div>

            <!-- Profile Contact Details -->
            <div class="mt-6 sm:mt-5  col-span-full">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Billing details
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                This information will be shown on your invoices from artists.
              </p>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
            >
              <label
                for="venue-billing-same-as"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                The venue billing address is the same as the venue address
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <t-toggle id="venue-billing-same-as" value="form.billing_same_as" @change="toggleBillingAddress"></t-toggle>
              </div>
            </div>

            <div class="mt-6 sm:mt-5 space-y-6  col-span-full sm:space-y-5" v-if="!form.billing_same_as">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <span>Billing address</span>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label for="venue-billing-address-company-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Company name
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <InputText 
                    id="venue-billing-address-company-name" 
                    v-on:input="form.billing_address.company_name = $event;" 
                    v-bind:value="form.billing_address.company_name"
                    name="venue-address-company-name" 
                    class="max-w-lg sm:max-w-xs"
                  />  
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label
                  for="venue-billing-address-line1"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing Line 1
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <InputText 
                    id="venue-billing-address-line1" 
                    v-on:input="form.billing_address.address_line_1 = $event;" 
                    v-bind:value="form.billing_address.address_line_1"
                    error="The venue address line one must be provided" 
                    name="venue-address-line1" 
                    class="max-w-lg sm:max-w-xs"
                  />  
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-line2"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing Line 2 (Optional)
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="venue-billing-address-line2"
                    id="venue-billing-address-line2"
                    v-model="form.billing_address.address_line_2"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-billing-address-town"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing Town/City
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <InputText 
                    id="venue-billing-address-town" 
                    v-on:input="form.billing_address.town = $event;" 
                    v-bind:value="form.billing_address.town"
                    error="The venue's billing town must be provided" 
                    name="venue-billing-address-town" 
                    class="max-w-lg sm:max-w-xs"
                  /> 
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-billing-address-county"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                 Billing County
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2"> 
                  <InputText 
                    id="venue-billing-address-county" 
                    v-on:input="form.billing_address.county = $event;" 
                    v-bind:value="form.billing_address.county" 
                    error="The venue's billing county must be provided" 
                    name="venue-billing-address-county" 
                    class="max-w-lg sm:max-w-xs"
                  />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Billing Postcode
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <InputText 
                    id="venue-billing-address-postcode" 
                    v-on:input="form.billing_address.post_code = $event;" 
                    v-bind:value="form.billing_address.post_code"  
                    error="The venue's billing postcode must be provided" 
                    name="venue-billing-address-postcode" 
                    class="max-w-lg sm:max-w-xs"
                  />
                </div>
              </div>
            </div>
              <div
                  class="input-group sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                    for="website"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Currency
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div class="mt-1 sm:mt-0 sm:col-span-2 pt-0">
                    <TRichSelect
                        id="currencies"
                        name="currencies"
                        v-model="form.currency.id"
                        :options="currencies"
                        @change="pickCurrency"
                        value-attribute="id"
                        text-attribute="display_name"
                        class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />

                  </div>
                </div>
              </div>
            </div> 
        </form>
        <div class="action-bar">                
          <GPButton @click.native="save()"
                    :disabled="btnDisabled"
                    :loading="btnDisabled"
                    color="pink">Create Venue</GPButton>                
        </div> 
    </div>
		</template>



      
    <NotificationSmall ref="notification" />
    <VenueLocationModal ref="locationModal" v-on:saveLocation="storeLocation($event)" v-on:updateCountry="pickCountry($event)"/>
  </div>
</template>

<script>
import { TRichSelect, TToggle } from 'vue-tailwind/dist/components';
import PageSpinner from '../../components/ui/spinner/PageSpinner.vue';
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';
import InputTags from '../../components/form/InputTags.vue';
import ProfilePictureUpload from '../../components/uploads/profile-picture-upload.vue'
import UploadGallery from '../../components/uploads/upload-gallery.vue'
import InputText from '../../components/form/InputText.vue';
import InputTextArea from '../../components/form/InputTextArea.vue';
import Pristine from '../../utils/pristine.js';
import GPButton from '../../components/ui/buttons/Button.vue';
import Button from '../../components/ui/buttons/Button.vue'
import VenueLocationModal from '../../components/modal/VenueLocation.vue';
import { apiMethods } from "@/state/helpers";
import { TrashIcon } from '@vue-hero-icons/outline'
import NotificationSmall from '../../components/notifications/small.vue';
import client from "../../utils/client"
import {DEFAULT_CURRENCY_ID} from "@/constants/contants";
import Accordian from '../../components/accordian/Accordian.vue';

export default {
  name: "VenueAdd",
  components: {
    TToggle,
    TRichSelect,
		PageSpinner,
		NoDataMsgWrapper,
    InputTags,
		ProfilePictureUpload,
		UploadGallery,
    InputText,
    InputTextArea,
    GPButton,
    Button,
    VenueLocationModal,
    TrashIcon,
    NotificationSmall,
    Accordian
  },
  data() {
    return {
			isLoading:false,
      btn: false,
      pristineConfig: {
          classTo: 'input-group',
          errorClass: 'has-error',
          successClass: 'has-success',
          errorTextParent: 'input-error',
          errorTextTag: 'span',
          errorTextClass: 'text-help'
      }, 
			venueTags:null,
			icon:null,
			gallery:[],
      location: [],
      countries: [],
      currencies: [],
      userVenues: [],
      form: {
        name: "",
        about: "",
        venueTags: [],
        address: {},
        billing_address: {},
        billing_same_as: false,
        email: "",
        telephone: "",
        website: "",
        currency: {id: DEFAULT_CURRENCY_ID},
        venueId: null
      },
    };
  },
	computed:{
		hasData(){
			if(this.venueTags){
				return true;
			}
			return false;
		},
    btnDisabled(){
        return this.btn;
    },
	},
  mounted() {

    client.get('countries').then(response => (this.countries = response.data.data))
    client.get('currencies').then(response => (this.currencies = response.data.data))
    client.get('admin-venues').then(response => (this.userVenues = response.data.data))

		this.isLoading = true;

		Promise.all([
			//  Tags
			this.$store.dispatch('venue/getTags')
			.then((response) => {
				this.venueTags = response
				console.log(response)
			})
		]).finally(() => {
			this.isLoading = false;
			setTimeout(() => {
				this.pristine = new Pristine(this.$refs.form, this.pristineConfig);      
				// this.pristine.addValidator('url', function(value) {
				// 		return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
				// }, "A valid website is required (including https://)", 1, false);
			}, 500)
			
			
		});


  },
  methods: {
    ...apiMethods,
    pickTag(){
      this.$refs.tagoutput.addTag(this.$refs.tagpicker.selectedOption);
    },
    pickCountry(value){
        this.form.address.country_id = value
    },
		profilePictureUploaded(image) {
			this.icon = image
		},
		onGalleryChanged(images) {
			this.gallery = images
		},

    toggleBillingAddress() {
      this.form.billing_same_as = !this.form.billing_same_as
    },
    storeLocation($location){
      this.$refs.notification.createNotification('Location successfully added!', false);
      this.location = $location;
    },
    removeLocation(){
      this.$refs.notification.createNotification('Location successfully removed!', false);
      this.location = [];
    },
    save() {
      this.btn = true;
      this.submitting = true;
      let valid = this.pristine.validate(); 
 
      if(!this.form.name)
      {
        this.$refs.notification.createNotification('You must choose a venue name', true);
        this.submitting = false;
        this.btn = false;
        return;
      }

      if(this.form.venueTags.length == 0){
        this.$refs.notification.createNotification('You must choose a venue tag', true);
        this.submitting = false;
        this.btn = false;
        return;
      }

      if(this.location.length === 0){
        this.$refs.notification.createNotification('You must choose a venue location', true);
        this.submitting = false;
        this.btn = false;
        return;
      }

        if(this.form.address.country_id === undefined){
            this.$refs.notification.createNotification('You must choose a country', true);
            this.submitting = false;
            this.btn = false;
            return;
        }

      if(!this.form.billing_same_as && (this.form.billing_address.address_line_1 == null || this.form.billing_address.town == null ||
          this.form.billing_address.county == null || this.form.billing_address.post_code == null))
      {
          this.$refs.notification.createNotification('You must complete your billing address details', true);
          this.submitting = false;
          this.btn = false;
          return;
      }

      if (this.form.billing_same_as) {
        this.form.address.company_name = this.form.name;
      }

			if (valid && this.submitting){

				let venue_tags = this.$refs.tagoutput.tags;

				const payload = {
					name: this.form.name,
					about: this.form.about,
					venue_tags:venue_tags.map((venueTag) => venueTag.value),
					icon:this.icon !== null ? this.icon.id : null,
					gallery: this.gallery.map(gallery => gallery.id),
					address: this.form.address,
					billing_same_as: this.form.billing_same_as,
					billing_address: this.form.billing_same_as ? null : this.form.billing_address,
					email: this.form.email,
					telephone: this.form.telephone,
					website: this.form.website,
					published:false,
          location: this.location.id,
          currency_id: this.form.currency.id,
          copiedVenueId: this.form.venueId
				}

          this.$store.dispatch('venue/createVenue', payload)
          .then(() => {
            this.$refs.notification.createNotification("Venue created", false);
            this.$router.push('/gigs/add');
            this.btn = false;
          }).catch((error) => {
            this.$notify({text: "An error occurred: " + error.message + ".  If this message persists, please contact support@gigpig.uk quoting this error message",  duration: 5000});
            this.btn = false;
          });
      }else{
				this.$refs.notification.createNotification("Please check the form for omissions", true);
        this.btn = false;
      }

    },
    pickCurrency(value) {
      this.form.currency = this.currencies.find(c => c.id === value)
    },
    selectVenueToCopy(value) {
      this.form.venueId = value
    }
  },
};
</script>
