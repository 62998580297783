<template>
	<GigPageWrapper
		:gig-id="String($attrs.id)" 
		current-route="instructions"
		title="Artist instructions"
		@loaded="onCalendarWrapperLoaded"
	>

        <InlineNotification v-if="!isGigCancelled" Heading="Setting artist instructions" DismissId="Instructions">
            <template slot="body">
            <p>This is the information each artist will see about the gig. Give as much information at this point as you can.</p>
            </template> 
        </InlineNotification> 

        <InlineNotification v-else Heading="Access denied">
            <template slot="body">
                <p class="mt-2 text-justify">This feature is reserved for gigs that have not been cancelled. Please navigate to the gig details page using the component below if you wish to see the full detailed breakdown of this cancelled gig.</p>
                <router-link :to="'/gigs/' + $attrs.id" class="flex items-center mt-1 bg-transparent w-48"><ArrowCircleLeftIcon class="mr-2" color="pink"/>Go to gig details page</router-link>
            </template> 
        </InlineNotification>  

        <form v-show="!isGigCancelled && isLoaded" class="form" ref="form" novalidate>

            <h2 class="col-span-full pt-4">Setup Details</h2>

            <div v-show="showPayByCash" class="sm:col-span-6 grid grid-flow-col">
                <div class="flex p-3 border border-gray-200 rounded-lg">
                    <label class="font-bold text-sm text-gray-700">Pay artist by cash</label>
                    <t-toggle v-model="form.pay_artist_in_cash" class="ml-3 w-11"/>
                </div>
            </div>

            <div class="sm:col-span-6 grid grid-flow-col ">
                <input-text-area :rows="rowHeight"  type="textarea"  id="load_in_time" label="Load in times" name="load_in_time" v-on:input="form.load_in_time = $event" v-bind:value="form.load_in_time" placeholder="e.g. You can gain access to the room any time during the day. If you are planning on setting up earlier then please check with the venue first" />
            </div>
            <div class="sm:col-span-6 grid grid-flow-col ">
                <input-text-area :rows="rowHeight"  type="textarea"  id="sound_check_time" label="Sound check times" name="sound_check_time" v-on:input="form.sound_check_time = $event" v-bind:value="form.sound_check_time" placeholder="e.g. Must be checked by 7pm"  />
            </div>
            
            <div class="sm:col-span-6 grid grid-flow-col ">
                <input-text-area :rows="rowHeight"  type="textarea"  id="performance_times" label="Performance times" name="performance_times" 
                placeholder="e.g. 7:30pm – 8:30pm, 9pm - 10pm "  v-on:input="form.performance_times = $event" v-bind:value="form.performance_times"  />
            </div>
            <h2 class="col-span-full pt-4">Equipment Details</h2>
            <div class="sm:col-span-6 grid grid-flow-col  ">
                <input-text-area :rows="rowHeight"  type="textarea"  id="equipment_venue"  v-on:input="form.equipment_venue = $event" v-bind:value="form.equipment_venue" placeholder="e.g. Venue will only supply sound system" label="Equipment provided by venue" name="equipment_venue"/>
            </div>
            <div class="sm:col-span-6 grid grid-flow-col ">
                <input-text-area :rows="rowHeight"  type="textarea"  id="equipment_artist" v-on:input="form.equipment_artist = $event" v-bind:value="form.equipment_artist" placeholder="e.g. Must provide own stereo jack cables and mic cables." label="Equipment provided by artist" name="equipment_artist"/>
            </div>
            
            <h2 class="col-span-full pt-4">Performance Notes</h2>
            <div class="sm:col-span-6 grid grid-flow-col ">
                <input-text-area :rows="rowHeight"  type="textarea"  id="repertoire" label="Music guidelines" name="repertoire"  v-on:input="form.repertoire = $event" v-bind:value="form.repertoire" placeholder="e.g. A good mix of old classics and current tunes is essential. Keep your sets as upbeat as possible, even if the venue doesn’t seem that busy."/>
            </div>
            <div class="sm:col-span-6 grid grid-flow-col ">
                <input-text-area :rows="rowHeight"  type="textarea"  id="dress_code" label="Dress code" name="dress_code" placeholder="e.g. Smart Casual" v-on:input="form.dress_code = $event" v-bind:value="form.dress_code" />
            </div>
            <div class="sm:col-span-6 grid grid-flow-col ">
                <input-text-area :rows="rowHeight"  type="textarea"  id="parking_details" label="Parking details" name="parking_details"  v-on:input="form.parking_details = $event" v-bind:value="form.parking_details" placeholder="e.g. Loading available outside of venue. Free public parking close to venue" />
            </div>
            <div class="sm:col-span-6 grid grid-flow-col ">
                <input-text-area  :rows="rowHeight"  type="textarea"  id="additional_notes" v-on:input="form.additional_notes = $event" v-bind:value="form.additional_notes" label="Additional notes" name="additional_notes"  placeholder="e.g. Musician to ask for the manager on duty upon arrival"/>
            </div>

            <div class="sm:col-span-6  grid-flow-col">
                <div class="mt-1 input-group">
                    <label for="briefs" class="block text-sm font-medium text-gray-700"> Briefs </label>
                    <div class="input-content min-h-[138px] tiptap-content">
                        <tiptap @input="setBriefs" :id="'briefs'" :value="form.briefs" tabindex="0" class="tiptap-container"/>
                        <div class="input-error"></div>
                    </div>
                </div>
            </div>

            <div class="sm:col-span-6 grid grid-flow-col ">
                <input-text-area :rows="rowHeight"  type="textarea"  id="payment_terms" v-on:input="form.payment_terms = $event" v-bind:value="form.payment_terms"  label="Payment terms" name="payment_terms" placeholder="e.g. Let the artist know when and how they will get paid." required/>
            </div>
            <div class="sm:col-span-6  grid-flow-col hidden ">
                <input-text-area :rows="rowHeight"  type="textarea"  id="invoicing" v-on:input="form.invoicing = $event" v-bind:value="form.invoicing" label="Invoicing" name="invoicing" placeholder="e.g. If you are completing work on a regular weekly basis we ask that you submit your weekly invoice as promptly as possible, (ideally on a Sunday)." />
            </div>
            <div class="sm:col-span-6  grid-flow-col  hidden ">
                <input-text-area :rows="rowHeight"  type="textarea"  id="important" v-on:input="form.important = $event" v-bind:value="form.important"  label="IMPORTANT INFORMATION" name="important" placeholder="e.g. Your Invoice must be made out to: ABC Music Ltd, 10 Samson Close" />
            </div>
        </form>

                
                <div v-if="this.isLoaded" class="action-bar">       
									
									<template v-if="isGigCancelled">
										<span class="text-xs uppercase justify-center h-full text-gray-600">This gig has been cancelled</span>
									</template>
									<template v-else-if="this.item && this.item.published && this.item.bookings.length == 0">
										<span class="text-xs uppercase justify-center h-full text-gp_pink-default-500">GigPig is looking for artists for this gig</span>
									</template>

                    <template v-if="hasManageGigsPermission">
                        <GPButton v-if="showDeleteButton"
                                    @click.native="deleteItem()"
                                    :disabled="btnDisabled"
                                    color="indigo"
                                    size="lg"
                                    ><TrashIcon class="mr-2"/>Delete gig</GPButton>
                    </template>	


                    <template v-if="!isGigCancelled">
                        <GPButton             v-if="!item.cancelled"
										@click.native="save(false)" 
										:disabled="btnDisabled"
                                        :loading="btnDisabled"
										size="lg"
									><SaveIcon class="mr-2"/>Save & Next</GPButton> 
                    </template>
                </div>

                <NoDataMsgWrapper v-if="!this.isLoaded && !isGigCancelled">Loading the artist instructions for this gig. Please wait.</NoDataMsgWrapper>

        <NotificationSmall ref="notification" />

    <DeleteGigConfirmation ref="deletegig" />

	</GigPageWrapper>

</template>

<script>  
import Pristine from '../../utils/pristine.js'
import InputTextArea from '../../components/form/InputTextArea.vue';
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue'; 
import GPButton from '../../components/ui/buttons/Button.vue'
import { apiComputed, apiMethods } from '@/state/helpers'; 
import InlineNotification from '../../components/notifications/inline.vue' 
import NotificationSmall from '../../components/notifications/small.vue';
import formatDate from '../../utils/format-date.js';
import { TrashIcon, SaveIcon, ArrowCircleLeftIcon } from "@vue-hero-icons/outline";
import DeleteGigConfirmation from '@/components/modal/DeleteGigConfirmation.vue';
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import NProgress from 'nprogress';
import Tiptap from "@/components/form/Tiptap.vue";
import { TToggle } from 'vue-tailwind/dist/components'

export default {
    name: "GigInstructions",
    components:{
        Tiptap,
        GigPageWrapper, 
        GPButton,
        DeleteGigConfirmation,
        InputTextArea,
        NotificationSmall,
        InlineNotification,
        NoDataMsgWrapper,
        TrashIcon, SaveIcon, ArrowCircleLeftIcon,
        TToggle
    },  
    data ()
    {
 
       return { 
			isLoading:false,
            isLoaded: false,
            btn: false,
            item:{},
            form: {},
            gigCopy: '',            
            pristine: null,
            pristineConfig: {
                classTo: 'input-group',
                errorClass: 'has-error',
                successClass: 'has-success',
                errorTextParent: 'input-error',
                errorTextTag: 'span',
                errorTextClass: 'text-help'
            },
            showPayByCash: false,
            rowHeight: 4, 
            pay_artist_in_cash: false,
            equipment_artist: '',
            equipment_venue: '',
            repertoire: '',
            dress_code: '',
            parking_details: '',
            load_in_time: '',
            sound_check_time: '',
            performance_times: '',
            additional_notes: '',
            invoicing: '',
            important: '',
            briefs: '',
            payment_terms: ''
       }
    },
    mounted() {
        NProgress.start();
        this.getGigDetailsWithOffers(
            {
              id: this.$attrs.id,
              useTrashedArtists: false,
              queryParams: ['bookings','venue']
            }).then((resp) => {
            this.item = resp;

            console.log(resp);

            if (this.item.venue.payment_process == "ryft" && sessionStorage.getItem('selected_venue') == null) {

                let selected_venue = {
                    credits_remaining: this.item.venue.active_tokens.length,
                    id: this.item.venue.id,
                    name: this.item.venue.name,
                    expiry_year: this.item.venue.expiry_year,
                    expiry_month: this.item.venue.expiry_month,
                    payment_process: this.item.venue.payment_process,
                    currency_id: this.item.venue.currency_id
                }

                sessionStorage.setItem('selected_venue', JSON.stringify(selected_venue));
                window.location.reload();
            }

            if (this.item.venue.payment_process == "ryft" && !this.item.venue.payg_monthly) {
                this.showPayByCash = true;
            }
            
            this.form = resp;
            this.$store.dispatch('gig/storeGig', this.item);
            this.onCalendarWrapperLoaded();
            this.isLoaded = true;
            NProgress.done();
        });
    },
  computed: {
        ...apiComputed, 
		isGigCancelled(){
			return this.$store.getters['gig/isCurrentGigCancelled']
		}, 
        showDeleteButton()
        {
            return this.item.status === 'UNPUBLISHED' || this.item.status === 'CANCELLED' || this.item.status === 'PENDING' || this.item.status === 'NEEDS_RESPONSE';
        },
        hasManageGigsPermission(){
				return this.$store.getters['user/hasManageGigsPermission']
		},
        btnDisabled(){
            return this.btn;
        },
	}, 
    methods:{
      setBriefs(value) {
        this.form.briefs = value
      },
        formatDate,
        ...apiMethods,
        onCalendarWrapperLoaded(){
            if(!this.pristine && this.$refs.form){
                this.pristine = new Pristine(this.$refs.form, this.pristineConfig);
            }
        },
        save(published = false){
            this.btn = true;
             
            var valid = this.pristine.validate(); 

            if (!this.form.payment_terms) {
                this.btn = false;
                this.$refs.notification.createNotification('Please fill in the payment terms', true);
                return;
            }
            
            if (!this.form.payment_terms) {
                this.btn = false;
                this.$refs.notification.createNotification('Please fill in the payment terms', true);
                return;
            }

            if (valid)
            {       
                const fallbackPublished = this.item ? this.item.published : published;
    
                let payload = { 
                    id: this.item.id,  
                    name: this.item.name,
                    start: this.item.start,
                    end: this.item.end,
                    paused: this.item.paused,
                    published: published ? published : fallbackPublished, // don't change publish value on save
                    publish_now: published,
                    artist_instruction: '',  
                    pay_artist_in_cash: this.form.pay_artist_in_cash,
                    equipment_artist: this.form.equipment_artist,
                    equipment_venue: this.form.equipment_venue,
                    repertoire: this.form.repertoire,
                    dress_code:  this.form.dress_code,
                    parking_details: this.form.parking_details,
                    load_in_time:  this.form.load_in_time,
                    sound_check_time: this.form.sound_check_time,
                    performance_times: this.form.performance_times,
                    additional_notes: this.form.additional_notes,
                    invoicing: this.form.invoicing,
                    important: this.form.important,
                    briefs: this.form.briefs,
                    payment_terms: this.form.payment_terms,
                    search_type: this.item.search_type
                }

                this.updateGig(payload)
                    .then(() => { 
                            this.$router.push('/gigs/' + this.item.id + '/choose-artists'); 
                            this.$notify("Instructions saved");
                            this.btn = false;
 
                    }).catch($ex => {
                        this.$refs.notification.createNotification("An error has occurred - " + $ex.response.data.message, true);
                        this.btn = false;
                    })
            }
            else {                
                this.$refs.notification.createNotification("Please check the form for errors", true);
                this.btn = false;
            }
        },
        deleteItem(){ 
          this.$refs.deletegig.show(this.item);
        }
    }
}
</script>
