<template>

<div class="lg:ml-4">
    
    <InlineNotification Heading="Favourite artists">
        <template slot="body">
          <p>Creating your very own favourites list is like shopping for your favourite artists, and adding them to your “cart”.</p>
          <p>This allows you to quickly add a list of artists to your gig and send them gig offers. It’s really useful if you have a different style artist on different days of the week, for example, “Friday solo acoustic singers” and “Saturday house DJs”.</p>
                
         <p>Start by <router-link to="/artist-directory" class="text-gp_pink-default-500">browsing the GigPig artist directory</router-link> create a new list and add your favourites to it.
          </p>
          <t-input v-on:keyup.enter="loadData()" name="search" ref="search_name" placeholder="Search by name" v-model="search_phrase" />
        </template>
    </InlineNotification>

      <NoDataMsgWrapper v-show="!isFavesLoaded">
          Loading favourites. Please wait...
      </NoDataMsgWrapper> 
     
      <div v-show="isFavesLoaded">
        <div class=" flex flex-col mt-4 pb-24" v-if="faves && faves.length > 0">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
            >
              <div
                class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-200 text-left">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="relative px-6 py-3">
                        <span class="sr-only">Manage</span>
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Artists
                      </th> 
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr @click="$router.push('favourites/' + list.id)" class="relative cursor-pointer" v-for="(list, index) in faves" :key="index" :row="list">
                        <td class="p-6"> 

                          <!-- <router-link :to="list.id" class="">  -->
                          
                          <span class="text-gp_pink-default-500">Manage list and artists</span>
                        <!-- </router-link > -->
                        </td>
                        <td class=" text-left mx-2 p-6 justify-center h-full items-center">
                            <strong>{{list.name}}</strong>
                            <span v-if="list.is_shared" class="text-sm text-gp_pink-default-500"> &nbsp;&nbsp;&nbsp;(shared)</span>
                        </td> 
                        <td class="p-6">
                            <span>{{pluralize(list.artist_count , 'artist')}}</span> 
                        </td> 
                    </tr>
                  </tbody>
                </table>

                <template>
                <div v-show="this.paginate_total > 10" class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                  <div class="flex flex-1 justify-center sm:hidden">
                    <button @click="prevPage()" class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</button>
                    <button @click="nextPage()" class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Next</button>
                  </div>
                  <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                      <p class="text-sm text-gray-700">
                        Showing
                        <span class="font-medium">{{ this.paginate_from }}</span>
                        to
                        <span class="font-medium">{{ this.paginate_to }}</span>
                        of
                        <span class="font-medium">{{ this.paginate_total}}</span>
                        results
                      </p>
                    </div>
                    <div>
                      <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <button @click="changePage(1)" type="button" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                          <span class="sr-only">Previous</span>
                          <ChevronDoubleLeftIcon class="h-5 w-5" aria-hidden="true" />
                        </button>

                          <button @click="prevPage()" type="button" class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                            <span class="sr-only">Previous</span>
                            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                          </button>
                          <span v-show="this.paginate_currPage > 1" class="cursor-default	relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
                          <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" -->
                          <button type="button" aria-current="page" class="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{{ this.paginate_currPage }}</button>
                          <button v-show="this.paginate_currPage < this.paginate_lastPage - 3" @click="changePage(paginate_currPage + 1)" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">{{ this.paginate_currPage + 1 }}</button>
                          <button v-show="this.paginate_currPage < this.paginate_lastPage - 4" @click="changePage(paginate_currPage + 2)" class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">{{ this.paginate_currPage + 2 }}</button>
                          <span v-show="this.paginate_currPage <= this.paginate_lastPage - 6" class="cursor-default	relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
                          <button v-show="this.paginate_currPage < this.paginate_lastPage - 2" @click="changePage(paginate_lastPage - 2)" class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">{{ this.paginate_lastPage - 2}}</button>
                          <button v-show="this.paginate_currPage < this.paginate_lastPage - 1" @click="changePage(paginate_lastPage - 1)" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">{{ this.paginate_lastPage - 1 }}</button>
                          <button v-show="this.paginate_currPage !== this.paginate_lastPage" @click="changePage(paginate_lastPage)" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">{{ this.paginate_lastPage }}</button>
                          <button @click="nextPage()" type="button" class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                            <span class="sr-only">Next</span>
                            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                          </button>

                        <button @click="changePage(paginate_lastPage)" type="button" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                          <span class="sr-only">Next</span>
                          <ChevronDoubleRightIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              </template>


              </div>
            </div>
          </div>
        </div>
        <div v-else>
            <p class="mt-4">No favourites have been created, <router-link class="text-gp_pink-default-500" to="/artist-directory">use the artist directory to add your first artist</router-link>.</p>
        </div>
      </div> 

      <FeatureLocked />
      <SaveCard ref="savecardmodal"  />
</div>
</template>

<script>
 
import { apiComputed, authComputed, apiMethods } from '@/state/helpers'
import pluralize from '../../utils/pluralize.js'
import InlineNotification from '../../components/notifications/inline.vue';
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';
import client from "@/utils/client"
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@vue-hero-icons/solid'
import NProgress from 'nprogress'
import { TInput } from 'vue-tailwind/dist/components';
import FeatureLocked from '../../components/modal/FeatureLocked.vue'
import SaveCard from '../../components/modal/venues/subscriptions/SaveCard.vue'

export default
{
    name: "FavouritesList",
    components:
    {  
        InlineNotification, NoDataMsgWrapper,TInput,
        ChevronLeftIcon, ChevronRightIcon,ChevronDoubleLeftIcon,ChevronDoubleRightIcon,
        FeatureLocked,
        SaveCard
    },
    watch: {
      search_phrase: function(){
        this.loadData();
      }
    },
    data(){
      return {
        acc_info: JSON.parse(localStorage.getItem('acc_info')),
        faves : null,
        isLoading: false,
        paginate_to: null,
        paginate_from: null,
        paginate_total: null,
        paginate_lastPage: null,
        paginate_currPage: null,
        search_phrase: null
      }
    },
    mounted() {

      if (!this.acc_info.has_active_free_trial && !this.acc_info.has_active_legacy_subscription && !this.acc_info.has_active_venue_subscription && !this.acc_info.has_active_payg_tokens
        && this.acc_info.venue_count == 1 && this.acc_info.onboarded_using_new_onboard && !this.acc_info.has_active_tokens && !this.acc_info.free_trial_info
      ) {
          this.$refs.savecardmodal.addVenueInfoForTrial({ id: this.acc_info.onboarded_venue.venue_id , name: this.acc_info.onboarded_venue.venue_name });
      }

      this.loadData();
    },
    computed:{
        ...apiComputed,
        ...authComputed,
        isFavesLoaded(){
          return this.isLoading;
        }
    },
    methods:{
        ...apiMethods,
        pluralize,
        loadData(){
          NProgress.start();
          let queryParam = '?page=' + this.paginate_currPage

          if (this.search_phrase){
            queryParam += '&search=' + this.search_phrase;
          } 

          client.get('/favourites-lists-simple/' + queryParam).then((resp) =>{
            this.faves = resp.data.data;

            this.paginate_to = resp.data.to;
            this.paginate_from = resp.data.from;
            this.paginate_total = resp.data.total;
            this.paginate_lastPage = resp.data.last_page;
            this.paginate_currPage = resp.data.current_page;

            this.isLoading = true;
          })
          .finally(() => {
            NProgress.done();
          });
        },
        prevPage(){
          if (this.paginate_currPage !== 1) {
            window.scrollTo(0,0);
            this.paginate_currPage = this.paginate_currPage - 1;
            this.loadData();
          }
        },
        nextPage(){
          if (this.paginate_currPage !== this.paginate_lastPage){
            window.scrollTo(0,0);
            this.paginate_currPage = this.paginate_currPage + 1;
            this.loadData();
          }
        },
        changePage(val){
          window.scrollTo(0,0);
          this.paginate_currPage = val;
          this.loadData();
        },
    }
    
}

</script>