<template>
	<PageDetails>
		<h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">{{ getVenueName }}&nbsp;</h1> 

				<VenueTabs v-if="user && !user.is_opus && displayVenueTabs" :venue-id="String(venueId)" :venue-user-id="venueUserId" current-route="index" />
 
				<template v-if="isLoading">
					<div class="p-8 flex justify-center items-start">
						<PageSpinner 
							tailwind-diameter="8"
							tailwind-border="4"
							tailwind-color="pink-500"
						/>
					</div>
				</template>
				<template v-else-if="!isLoading && !hasData">
					<NoDataMsgWrapper>There is no venue data</NoDataMsgWrapper>
				</template>
				<template v-else>

					<div
						class="flex-1 gap-8 md:gap-4 relative z-0 flex flex-col lg:flex-row mb-36 mt-8 h-full overflow-hidden max-w-7xl "
					>
						<main
							class="flex flex-col flex-1 relative z-0 overflow-y-auto focus:outline-none gap-4 sm:gap-8"
						>
							<div class="relative"> 
								
								<agile v-if="!isSyncing">
									<div v-for="slide in venueGallery" :key="slide.id">
										<img :src="slide.url || slide.preview" :alt="venue.name + ' image'" />
									</div>
								</agile>

								
							</div>
							<div class="flex flex-row text-left gap-4 sm:gap-8 w-full">
								<img
									class="shadow self-start w-14 h-14 sm:w-24 sm:h-24 object-contain"
									:src="venueIcon"
									:alt="venue.name + ' logo'" 
								/>
								<div class="flex flex-col gap-1">
									<h1
										class="text-gp_pink-default-500 text-lg"
										style="font-weight:600"
									>
										{{ venue.name }}
									</h1>
									<!-- <div class="flex flex-row flex-wrap gap-4 mt-2">
										<span
											class="bg-gp_pink-default-500 text-white px-4 py-0.5 rounded-2xl"
											v-for="tag in venue.venue_tags" :key="tag.id"
										>
											{{ tag.name }}
										</span>
									</div> -->
								
									<div class="hidden sm:block">
										<VenueTagsDisplayVue :venue-tags="venue.venue_tags"/>
									</div>
								</div>
								
							</div>
							<div class="block sm:hidden">
								<VenueTagsDisplayVue :venue-tags="venue.venue_tags"/>
							</div>
							<div class="venue-description">
								<p v-for="(line, index) in formatStringLinebreaks(venue.about)" :key="index">{{ line }}</p>
							</div>

						</main>
						<aside
							class="relative px-0 lg:px-12 flex flex-col gap-8 flex-shrink-0 w-full md:w-96 h-full border-0 border-gray-200 lg:border-l"
						>
							<div class="venue-options">
								<router-link :to="'/calendar/' + venue.id">
									<svg
										class="text-gp_gray-default-600 h-7 w-7"
										viewBox="0 0 26 29"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M23.8333 2.65385H19.5V0.5H17.3333V2.65385H8.66667V0.5H6.5V2.65385H2.16667C1.59221 2.65442 1.04144 2.88152 0.635236 3.28532C0.229031 3.68912 0.0005736 4.23663 0 4.80769V26.3462C0.0005736 26.9172 0.229031 27.4647 0.635236 27.8685C1.04144 28.2723 1.59221 28.4994 2.16667 28.5H23.8333C24.4078 28.4994 24.9586 28.2723 25.3648 27.8685C25.771 27.4647 25.9994 26.9172 26 26.3462V4.80769C25.9994 4.23663 25.771 3.68912 25.3648 3.28532C24.9586 2.88152 24.4078 2.65442 23.8333 2.65385ZM2.16667 4.80769H6.5V6.96154H8.66667V4.80769H17.3333V6.96154H19.5V4.80769H23.8333V9.11539H2.16667V4.80769ZM2.16667 11.2692H7.58333V17.7308H2.16667V11.2692ZM16.25 26.3462H9.75V19.8846H16.25V26.3462ZM16.25 17.7308H9.75V11.2692H16.25V17.7308ZM18.4167 26.3462V19.8846H23.8333L23.8344 26.3462H18.4167Z"
											fill="currentColor"
										/>
									</svg>

									Manage gigs
								</router-link>
								<a v-if="editmode === false" @click="editmode = true" class="text-gp_pink-default-500 ">
									<PencilIcon />
									Edit venue
								</a>
								<span v-if="editmode === true">
									<a @click="showProfileEdit = true" class="text-gp_pink-default-500 mb-6">
										<IdentificationIcon />
										Edit identity
									</a>
									<a @click="showContactDetailsEdit = true" class="text-gp_pink-default-500 mb-6">
										<AtSymbolIcon />
										Edit contact details
									</a>
									<a v-if="!user.is_opus" @click="showBillingDetailsEdit = true" class="text-gp_pink-default-500 mb-6">
										<CreditCardIcon />
										Edit billing details
									</a>
								</span>
							</div>

							<iframe
								class="w-auto h-96"
								:src="
									'https://www.google.com/maps/embed/v1/place?q=' +
										formAddress(venue.address) +
										'&key=' +
										apiKey
								"
							/>
							<div class="flex flex-col text-left">
								<span class="text-gp_pink-default-500">{{ venue.name }}</span>
								<span>{{ venue.address.address_line_1 }}</span>
								<span v-if="venue.address.address_line_2">{{
									venue.address.address_line_2
								}}</span>
								<span>{{ venue.address.town }}</span>
								<span>{{ venue.address.post_code }}</span>
							</div>

							<div class="venue-contact">
								<div>
									<span>Email</span>
									<a :href="'mailto:' + venue.email">{{
										venue.email
									}}</a>
								</div>
								<div>
									<span>Tel</span>
									<a :href="'tel:' + venue.telephone">{{ venue.telephone }}</a>
								</div>
								<div v-if="venue.website">
									<span>Website</span>
									<a :href="venue.website" target="_blank" rel="noopener">{{
										venue.website.replace('https://', '')
									}}</a>
								</div>
							</div>
						</aside>
					</div>
					<transition
						:duration="1000"
						appear
						enter-active-class="animate__animated animate__bounceInUp"
						leave-active-class="animate__animated animate__bounceOutDown"
					>
						<div v-if="editmode" class="action-bar">
							<GPButton 
								color="gray" 
								size="lg" 
								:disabled="isUpdating" 
								@click.native="onCancel"
							>Cancel</GPButton> 
							<GPButton 
									@click.native="saveVenue" 
									:disabled="isUpdating" 
									:loading="isUpdating"
									size="lg"
							>{{ this.submitButtonLabel }}</GPButton> 
						</div>
					</transition>
				</template>
				
		

			<!-- Modals -->
			
			<template v-if="venue">
				<!-- Venue Profile Edit -->
				<ProfileIdentityEditForm
					:venue="venue"
					:is-open="showProfileEdit"
					@updated="syncVenue"
					@closed="onCloseModal"
				/>

				<ProfileContactDetailsEditForm
					:venue="venue"
					:is-open="showContactDetailsEdit"
          :countries-prop="countries"
					@updated="syncVenue"
					@closed="onCloseModal"
				/>

				<ProfileBillingDetailsEditForm
					:venue="venue"
					:is-open="showBillingDetailsEdit"
          :currencies-prop="currencies"
          :current-currency="venue.currency"
					@updated="syncVenue"
					@closed="onCloseModal"
				/>
			</template>
			

			<NotificationSmall ref="notification" />
		</PageDetails>
	
</template>


<script>
import PageDetails from '@/components/ui/wrappers/PageDetails.vue'
import VenueTabs from '../../components/venues/VenueTabs.vue';
import VenueTagsDisplayVue from '../../components/venues/VenueTagsDisplay.vue';
import PageSpinner from '../../components/ui/spinner/PageSpinner.vue';
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';
import ProfileIdentityEditForm from './ProfileIdentityEditForm'
import ProfileContactDetailsEditForm from './ProfileContactDetailsEditForm'
import ProfileBillingDetailsEditForm from './ProfileBillingDetailsEditForm'
import NotificationSmall from '../../components/notifications/small.vue';
import GPButton from '../../components/ui/buttons/Button.vue'
import { VueAgile } from "vue-agile";
import formatStringLinebreaks from '@/utils/format-string-linebreaks'
import { AtSymbolIcon, PencilIcon, IdentificationIcon, CreditCardIcon } from "@vue-hero-icons/outline"
import client from "@/utils/client";

export default {
  name: "VenueDetails",
  components: {
		PageDetails,
		VenueTabs,
		VenueTagsDisplayVue,
		PageSpinner,
		NoDataMsgWrapper,
    ProfileIdentityEditForm,
    ProfileContactDetailsEditForm,
    ProfileBillingDetailsEditForm,
		NotificationSmall,
		GPButton,
    agile: VueAgile,
    AtSymbolIcon,
    PencilIcon,
    CreditCardIcon,
    IdentificationIcon,
  },
  props:['venueId'],
  data() {
    return {
			venue: null,
      venueUserId: null,
      displayVenueTabs: false,
      showDummyNotification: false,
      editmode: false,
      showProfileEdit: false,
      showContactDetailsEdit: false,
      showBillingDetailsEdit: false,
      apiKey: process.env.VUE_APP_GOOGLE_APIKEY,
      GalleryOptions: {
        dots: true,
        navButtons: false,
        infinite: true,
        autoplay: true,
      },

			isLoading:false,
			isSyncing:false,
			isUpdating:false,
			hasData:false,
			countries: [],
      currencies: []
    };
  },
  computed: {
		user() {
            return this.$store.getters['user/userData']
        },
		getVenueName(){
			return this.venue ? this.venue.name : ""
		},
		getImageKey(){
			return new Date().valueOf();
		},
		venueGallery(){
			return this.venue.gallery
		},
		venueIcon(){
			if(this.venue.icon && this.venue.icon.url){
				return this.venue.icon.url
			}else if(this.venue.icon && this.venue.icon.preview){
				return this.venue.icon.preview
			}else{
				return require('@/assets/images/placeholder.svg')
			}
		},
		submitButtonLabel(){
			return this.isUpdating ? 'Saving' : 'Save'
		}
	},
  watch: {
    venueUserId() {
      this.displayVenueTabs = true
    }
  },
  mounted() { 

		if (sessionStorage.getItem('from_onboard') === 'true') {
			sessionStorage.removeItem('from_onboard');
			window.location.reload();
		}
		
		this.isLoading = true;

		client.get('countries').then(response => (this.countries = response.data.data))
		client.get('currencies').then(response => (this.currencies = response.data.data))

		Promise.all([
			// Venue
			this.$store.dispatch('venue/getCurrentVenue', this.venueId)
			.then((response) => {
				this.venue = response
        this.venueUserId = response.user.id
			}),
		]).finally(() => {
			this.isLoading = false;
			this.hasData = String(this.venue.id) ===  String(this.venueId);
		});
  },
  methods: {
    formatStringLinebreaks,
    formAddress() {
      let address = this.venue.address;
      return encodeURIComponent([
        this.venue.name,
		address.company_name,
        address.address_line_1,
        address.address_line2,
        address.town,
        address.post_code,
      ].join(", "));
    },
		onCancel(){
			this.editmode = false;
		},
		onCloseModal(){
			this.showProfileEdit= false;
      this.showContactDetailsEdit= false;
      this.showBillingDetailsEdit= false;
		},
    syncVenue(formData) {
			this.isSyncing = true;
			this.$store
				.dispatch("venue/syncVenue", formData)
				.then(response => {
					this.onCloseModal()
					this.venue = response;
					this.isSyncing = false;
				})
    },
	getVenueTags(){
		if(this.venue.venueTags && this.venue.venueTags.length){
			return this.venue.venueTags
		}else if(this.venue.venue_tags && this.venue.venue_tags.length){
			return this.venue.venue_tags.map(v => v.id);
		}else{
			return [];
		}
	},
    saveVenue() {
			this.isUpdating = true;
			const payload = {
				name: this.venue.name,
				about: this.venue.about,
				venue_tags:this.getVenueTags(),
				icon:this.venue.icon !== null ? this.venue.icon.id : null,
				gallery: this.venue.gallery.map(gallery => gallery.id),
				address: this.venue.address,
				billing_same_as: this.venue.billing_same_as,
				billing_address: this.venue.billing_same_as ? null : this.venue.billing_address,
				currency_id: this.venue.currency.id,
				email: this.venue.email,
				billing_email: this.venue.billing_email,
				telephone: this.venue.telephone,
				website: this.venue.website,
				published:true,
				location: this.venue.location.id,
				feature_multiple_bookings: this.venue.feature_multiple_bookings
			}
			
			this.$store.dispatch('venue/updateCurrentVenue', payload)
				.then((response) => {	
					this.$notify({text: "Venue details updated successfully",  duration: 5000});
					this.venue = response;
					// This is a wierd bug with the gallery. isSyncing forces the gallery to re-render
					this.isSyncing = true;
					setTimeout(() => {
						this.isSyncing = false;
					}, 50)
					setTimeout(() => {
						this.onCancel();
					}, 250)
				}).catch((error) => {
					const errorData = error.response.data.error;
					const errorMessages = Object.values(errorData).flat(); 
					this.$notify({text: "An error occurred: " + errorMessages[0] + "  If this message persists, please contact support@gigpig.uk quoting this error message",  duration: 10000});
				});

			this.isUpdating = false;
    }
  },
};
</script>
