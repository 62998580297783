<template>
	<TabsWrapper :tabs="tabs">
		<template v-slot:right-cta>
			<slot name="right-cta"></slot>
		</template>
	</TabsWrapper>
</template>

<script>
import TabsWrapper from '../../components/ui/tabs/TabsWrapper.vue';
import {mapState} from "vuex";

export default {
	components: {
		TabsWrapper,
	},
	props:{
		currentRoute:{
			type:String,
			required: true,
		},
		venueId:{
			type:String,
			required: true,
		},
    venueUserId:{
      types: [Number, null],
      required: true,
      default: null
    }
	},
	data(){
		return {
			tabs:this.setTabsData(this.venueId, this.currentRoute),
			selected_venue: JSON.parse(sessionStorage.getItem('selected_venue')),
		}
	},
	computed:{
		...mapState({
			userData: (state) => state.user.userData,
			userPermissions: (state) => state.user.rolesPermissionsSlugs,
			userRoles: (state) => state.user.rolesPermissionsSlugs,
			hasAllUserData: (state) => state.user.hasAllUserDataLoaded,
			venue: (state) => state.venue.currentVenue
		}),
		isVenueOwner() {
			return this.venueUserId !== null && this.venueUserId == this.userData.id ? true : false;
		}
		},
		watch: {
			userPermissions() {
				this.tabs = this.setTabsData(this.venueId, this.currentRoute)
			},
			userRoles() {
				this.tabs = this.setTabsData(this.venueId, this.currentRoute)
			},
			venue() {
				this.tabs = this.setTabsData(this.venueId, this.currentRoute)
			},
    },
	methods:{
		setTabsData(venueId, currentRoute){
			const tabs = [{ 
					title:"Details", 
					route:`/venues/${venueId}`, 
					current:currentRoute === 'index' || currentRoute === 'details'
			}]
			if(((this.userData && !this.userData.is_opus) || this.isVenueOwner) || (!this.userData && !this.userRoles && (currentRoute === 'manage-roles' || currentRoute === 'manage-teams'))){
				tabs.push({ 
						title:"Manage Roles", 
						route:`/venues/${venueId}/manage-roles`, 
						current:currentRoute === 'manage-roles'
					});
					tabs.push({ 
						title:"Manage Team", 
						route:`/venues/${venueId}/manage-team`,
						current:currentRoute === 'manage-team'
					});
				if (this.isVenueOwner) {

					if (this.selected_venue.payment_process == "stripe") {
						// tab for legacy stripe subscriptions
						tabs.push({ 
							title: "Manage Subscription", 
							route: `/venues/${venueId}/manage-subscription`,
							current: currentRoute === 'manage-subscription'
						});
					}

					if (this.selected_venue.payment_process == "ryft") {
						// tab for ryft subscriptions
						tabs.push({ 
							title: "Manage Subscription", 
							route: `/venues/${venueId}/manage-subscriptions`,
							current: currentRoute === 'manage-subscriptions'
						});
					}

					tabs.push({ 
						title: "Manage Cards", 
						route: `/venues/${venueId}/manage-cards`,
						current: currentRoute === 'manage-cards'
					});

				}
				
			}else if(this.userData && this.userRoles){
				if(this.userPermissions.includes('manage-team-members') || this.userPermissions.includes('full-access') || this.isVenueOwner){
					tabs.push({ 
						title:"Manage Roles", 
						route:`/venues/${venueId}/manage-roles`, 
						current:currentRoute === 'manage-roles'
					});
					tabs.push({ 
						title:"Manage Team", 
						route:`/venues/${venueId}/manage-team`,
						current:currentRoute === 'manage-team'
					});
				}

				if (this.userPermissions.includes('billing-invoices') || this.userPermissions.includes('full-access') || this.isVenueOwner) {

					if (this.selected_venue.payment_process == "stripe") {
						// tab for legacy stripe subscriptions
						tabs.push({ 
							title: "Manage Subscription", 
							route: `/venues/${venueId}/manage-subscription`,
							current: currentRoute === 'manage-subscription'
						});
					}

					if (this.selected_venue.payment_process == "ryft") {
						// tab for ryft subscriptions
						tabs.push({ 
							title: "Manage Subscription", 
							route: `/venues/${venueId}/manage-subscriptions`,
							current: currentRoute === 'manage-subscriptions'
						});
					}

					tabs.push({ 
						title: "Manage Cards", 
						route: `/venues/${venueId}/manage-cards`,
						current: currentRoute === 'manage-cards'
					});
				}
			}
			return tabs
		}
	},
	async mounted(){
		this.tabs = this.setTabsData(this.venueId, this.currentRoute);
	}
}
</script>