<template>
    <div class="w-[350px] xl:w-[500px] mt-16">
        <div class="w-full bg-gray-200 rounded-full">
            <div class="bg-gp_pink-default-400 text-xs font-medium text-gp_pink-default-400 text-center h-2 leading-none rounded-full" :style="{ width: percentage + '%' }" />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                percentage: 0.0
            }
        },
        props:['step'],
        watch: {
            step() {
                this.percentage = this.step * 7.7;
            },
        }
    }
</script>