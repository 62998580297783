<template>    
    <div class="space-y-2 w-68"> 

		<div v-if="!isGenreVenueUser">

        <h2 class="py-4 ml-3 font-semibold">Management</h2>
        <router-link v-if="!isOpus && !isGenreVenueUser" to="/dashboard" class="flex items-center px-2 py-2 text-sm font-medium rounded-md hover:bg-indigo-600 hover:text-white group">
            <HomeIcon /> 
            <span class="ml-2">Dashboard</span>
        </router-link>
		<router-link v-if="isOpus" to="/artist-directory" class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
				<MusicNoteIcon />
			<span class="ml-2">Artist directory</span>
			</router-link>
        <router-link v-if="!isGenreVenueUser" to="/venues" class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
            <OfficeBuildingIcon/>
            <span class="ml-2">Venue<span v-if="!isOpus">s</span></span>
        </router-link>

		<template v-if="hasAllUserData">

			<template v-if="hasManageGigsPermission">
				<router-link to="/gigs/add" class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
					<TicketIcon/>
					<span class="ml-2">Add a new gig</span>
				</router-link>
			</template>

			<template v-if="hasViewCalendarPermission">
				<router-link to="/calendar" class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
					<CalendarIcon /> 
					<span class="ml-2">Calendar</span>
				</router-link>
			</template>

			<template v-if="hasManageGigsPermission">
				<router-link to="/pending-gigs" class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
					<ClockIcon />
					<span class="ml-2">Pending gigs</span>
				</router-link>
			</template>

			<template v-if="hasManageGigsPermission">
        <div class="hidden">
          <router-link to="/cancellations-artists" class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
            <ExclamationIcon />
            <span class="ml-2">Artist Cancellations</span>
          </router-link>
        </div>

			</template>

			<router-link  v-if="(hasBillingInvoicesPermission || hasManageGigsPermission) && !isOpus" to="/past-gigs" class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
					<ClipboardCheckIcon />
					<span class="ml-2">Completed gigs</span>
			</router-link>
			

			<template v-if="hasBillingInvoicesPermission && !isOpus && (!userData || !userData.email.includes('genremusic'))">
				<router-link to="/invoices" class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
					<CurrencyPoundIcon />
					<span class="ml-2">Billing & invoices</span>
				</router-link>
			</template>

			<template v-if="hasAllUserData && hasBillingInvoicesPermission">
				<router-link to="/analytics" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
					<div class="flex items-center">
						<ChartPieIcon />
						<span class="ml-2">Analytics</span>
					</div>
					<div v-show="isLocked" class="flex items-center pl-[90px]">
						<span class="uppercase font-bold text-[10px] text-white px-2 py-1 space-x-1 mr-2 rounded-full bg-gp_pink-default-500 flex items-center">
							<ClockIcon stroke="white" class="w-4 h-4" />
							<LockClosedIcon stroke="white" class="w-4 h-4" />
						</span>
					</div>
				</router-link>
			</template>

			<template v-if="hasShowMonthlyInvoices && !isGenreVenueUser">
				<router-link to="/monthly-invoices" class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
					<CurrencyPoundIcon />
					<span class="ml-2">Monthly invoices</span>
				</router-link>
			</template>

			
		</template>

		<template v-if="!isGenreVenueUser">

			<h2 class="py-4 ml-3 font-semibold">Tools</h2>

			<template v-if="hasAllUserData && hasManageGigsPermission">
				<router-link to="/gigtemplates" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
						<TemplateIcon />
						<div class="flex gap-3 items-center">
							<span class="ml-2">Gig templates</span>
						</div>
						<div v-show="isLocked" class="flex items-center pl-14">
							<span class="uppercase font-bold text-[10px] text-white px-2 py-1 space-x-1 rounded-full bg-gp_pink-default-500 flex items-center">
								<ClockIcon stroke="white" class="w-4 h-4" />
								<LockClosedIcon stroke="white" class="w-4 h-4" />
							</span>
						</div>
				</router-link>
			</template>

			<template>
				<router-link to="/requests-to-play" class="items-center hidden px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
					<UserAddIcon/>
					<span class="ml-2">Requests to Play</span>
					<span class="uppercase font-bold text-[10px] ml-[60px] text-white px-2 rounded-full bg-red-500">New</span>
				</router-link>
			</template>
		</template>
 
		<router-link v-if="!isSchloss" to="/requests-to-play" class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
			<UserAddIcon/>
			<div class="flex items-center gap-3">
				<span class="ml-2">Requests to Play</span>
				<span v-show="requests_to_play_count > 0" class="text-[10px] px-2 rounded-full bg-red-500"> {{ requests_to_play_count }}</span>
			</div>
			<div v-show="isLocked" :class="{'flex items-center pl-2': requests_to_play_count > 0, 'flex items-center pl-10': requests_to_play_count == 0 }">
				<span class="uppercase font-bold text-[10px] text-white px-2 py-1 space-x-1 mr-2 rounded-full bg-gp_pink-default-500 flex items-center">
					<ClockIcon stroke="white" class="w-4 h-4" />
					<LockClosedIcon stroke="white" class="w-4 h-4" />
				</span>
			</div>
		</router-link>
       

        <router-link v-if="!isOpus" to="/artist-directory" class="text-indigo-100 hover:bg-indigo-600 group flex items-center space-x-12 px-2 py-2 text-sm font-medium rounded-md">
			<div class="flex items-center">
				<MusicNoteIcon />
				<span class="ml-2">Artist directory</span>
			</div>
			<div v-show="isLocked" class="flex items-center pl-1">
				<span class="uppercase font-bold text-[10px] text-white px-2 py-1 space-x-1 mr-2 rounded-full bg-gp_pink-default-500 flex items-center">
					<ClockIcon stroke="white" class="w-4 h-4" />
					<LockClosedIcon stroke="white" class="w-4 h-4" />
				</span>
			</div>
        </router-link>

        <router-link to="/favourites" class="text-indigo-100 hover:bg-indigo-600 group flex items-center space-x-6 px-2 py-2 text-sm font-medium rounded-md">
			<div class="flex items-center">
				<StarIcon />
				<span class="ml-2">My favourite artists</span>
			</div>
			<div v-show="isLocked" class="flex items-center">
				<span class="uppercase font-bold text-[10px] text-white px-2 py-1 space-x-1 mr-2 rounded-full bg-gp_pink-default-500 flex items-center">
					<ClockIcon stroke="white" class="w-4 h-4" />
					<LockClosedIcon stroke="white" class="w-4 h-4" />
				</span>
			</div>
		</router-link>


			<router-link v-show="isGenre" to="/custom-tags"   class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
				<TagIcon />
				<span class="ml-2">Custom Tags</span>
			</router-link>

			<router-link to="/messages" class="items-center hidden px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
				<AnnotationIcon/>
				<span class="ml-2">Messages</span>
			</router-link>

			<router-link to="/house-rules" class="flex items-center px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
				<ClipboardListIcon/>
				<div class="flex justify-between w-full">
					<span class="ml-2">GigPig house rules</span> 
				</div>
			</router-link>

        <router-link to="/messages" class="items-center hidden px-2 py-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group">
            <AnnotationIcon/>
            <span class="ml-2">Messages</span>
        </router-link>

        <a href="#" class="items-center hidden px-2 text-sm font-medium text-indigo-100 rounded-md hover:bg-indigo-600 group py-2s">
            <MailIcon/>
            <span class="ml-2">Email templates</span>
        </a> 
		</div>
		<div v-else>
			<template v-if="hasViewCalendarPermission">
				<router-link to="/calendar" class="text-indigo-100 hover:bg-indigo-600  group flex items-center px-2 py-2 text-sm font-medium rounded-md">
					<CalendarIcon />
					<span class="ml-2">Calendar</span>
				</router-link>
			</template>
		</div>
	</div>
</template>

<script>
import { HomeIcon, LockClosedIcon, ClipboardListIcon, CalendarIcon, UserAddIcon, TemplateIcon, TicketIcon, OfficeBuildingIcon, MusicNoteIcon, AnnotationIcon, MailIcon, ClockIcon, ExclamationIcon, CurrencyPoundIcon, ClipboardCheckIcon, StarIcon, TagIcon, ChartPieIcon  } from "@vue-hero-icons/outline";
import client from './../../../utils/client';

export default {
    name: "NavEvent",
    components: { HomeIcon, LockClosedIcon, ClipboardListIcon, CalendarIcon, UserAddIcon, TemplateIcon, TicketIcon, OfficeBuildingIcon, MusicNoteIcon, AnnotationIcon, MailIcon, ClockIcon,ExclamationIcon, CurrencyPoundIcon, ClipboardCheckIcon, StarIcon, TagIcon, ChartPieIcon },
	computed:{
		user(){
			return this.$store.getters['user/userData'];
		},
		hasAllUserData(){
			return this.$store.getters['user/hasAllUserDataLoaded']
		},
		hasManageGigsPermission(){
			return this.$store.getters['user/hasManageGigsPermission']
		},
		hasViewCalendarPermission(){
			return this.$store.getters['user/hasViewCalendarPermission']
		},
		hasBillingInvoicesPermission(){
			return this.$store.getters['user/hasBillingInvoicesPermission']
		},
		hasManageTeamMembersPermission(){
			return this.$store.getters['user/hasManageTeamMembersPermission']
		},
		hasReferralCodeGenre(){
			return this.$store.getters['user/hasReferralCodeGenre']
		},
		hasShowMonthlyInvoices(){
			return this.$store.getters['user/hasShowMonthlyInvoices']
		},
		userData(){
			return this.$store.getters['user/userData']
		},
		isGenre() {
			return this.userData && this.userData.email && this.userData.email.includes('genremusic')
		},
		isOpus() {
			return this.userData && this.userData.is_opus == true;
		},
		isLocked() {
			return this.acc_info && this.acc_info.onboarded_using_new_onboard && (this.acc_info.has_active_free_trial || !this.acc_info.has_active_venue_subscription) && !this.acc_info.has_active_legacy_subscription
		},
		isGenreVenueUser(){
			return this.userData && this.userData.is_genre_venue == true;
		},
		isSchloss() {
			return this.userData.brand && this.userData.brand.name === "Albert Schloss";
		}
	},
	data() {
		return {
			requests_to_play_count: 0,
			acc_info: JSON.parse(localStorage.getItem('acc_info'))
		}
	},
	watch: {
		$route(to, from) {
			if (from.path == '/requests-to-play') {
				this.calculateRequestsToPlay();
			}
		},
		userData() {
			this.isGenre = this.userData.email.includes('genremusic')
		}
	},
	mounted() {
		this.calculateRequestsToPlay();
	},
	methods: {
		calculateRequestsToPlay() 
		{
			client.get('requests-to-play/all-requests-count').then(response => (this.requests_to_play_count = response.data))
		}
	}
}
</script>