// src/store/modules/payments.js

// import client from "../../utils/client"
import axios from "@/utils/client";

const state = { payments: null };

const mutations = {};

export const actions = {
    createMerchantPaymentSession({ commit }, params) {

        console.log('createMerchantPaymentSession commit', commit);

        return axios
            .put(`/payments/createMerchantPaymentSession`, params)
            .then(response => {
                console.log('createMerchantPaymentSession response', response);
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    createMerchantCustomer({ commit }, params) {

        console.log('createCustomer commit', commit);

        return axios
            .put(`/payments/createCustomer`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    createPaymentCard({ commit }, params) {

        console.log('createPaymentCard commit', commit);

        return axios
            .post(`/payments/createPaymentCard`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    updatePaymentCard({ commit }, params, cardId) {
        console.log('updatePaymentCard commit', commit);
        return axios
            .post(`/payments/updatePaymentCard/${cardId}`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    addPayg({ commit }, params) {
        console.log('makePAYGPayment commit', commit);
        return axios
            .post(`/payments/venue/${params.venue_id}/payg`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    addSubscription({ commit }, params) {
        console.log('addSubscription commit', commit);
        return axios
            .post(`/payments/venue/${params.venue_id}/subscriptions/${params.subscription_id}`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getVenueSubscriptionsInvoices({ commit }, params) {
        console.log('getVenueSubscriptions commit', commit);
        return axios
            .get(`/payments/venues/${params.venue_id}/payments`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getSubscriptionPlans() {
        return axios
            .get(`/payments/subscriptionPlans`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getVenuePaymentCards({ commit }, params) {
        console.log('getVenuePaymentCards commit', commit);
        return axios
            .get(`/payments/venues/${params.venue_id}/paymentCards`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    updateVenueBillingDetails({ commit }, params) {
        console.log('updateVenueBillingDetails commit', commit);
        return axios
            .put(`/update-venue/${params.venue_id}/billing-details`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    useVenueTokenForGigBooking({ commit }, params) {
        console.log('useVenueTokenForGigBooking commit', commit);
        return axios
            .put(`/payments/tokens/${params.gig_id}`)
            .then(response => {
                return response.data
            })
    },
    getVenuePaymentCard({ commit }, params) {
        console.log('getVenuePaymentCard commit', commit);
        return axios
            .get(`/payments/paymentCards/${params.card_id}`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    checkTokensFullyPaid({ commit }, params) {
        console.log('checkTokensFullPaid commit', commit);
        return axios
            .get(`/payments/tokens/${params.payment_log_id}/paid-tokens`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    }

};

const getters = {
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
