<template>
	
	<ModalWrapper 
		ref="deleteBookingModal" 
		:is-open="isOpen"
	>
		<template #header>
			<div class="text-xl">{{ modalTitle }}</div>
			<p class="mt-2 text-md">{{ introText }}</p>
		</template>

		<template #body>
			
			<template v-if="hasBookings"> 
				<div class="flex flex-col gap-4 sm:gap-8 my-6 sm:my-8">
					<div class="flex items-center border-red">
						<div class="mr-8">
							<SwitchToggle 
								:enabled="cancelEntirely"
								:disabled="isLoading"
								@updateEnable="onDeleteTypeUpdated"
								id="all" 
							/>
						</div>
						<div class="">
              <strong>{{ cancellationText }}</strong> This gig will be shown as {{ artistCancelledBooking ? "'Artist cancelled'" : "'cancelled'" }} on&nbsp;calendars.

            </div>
					</div>
					<div class="flex items-center border-red" v-show="booking">
						<div class="mr-8">
							<SwitchToggle 
								:enabled="removeArtist"
								:disabled="isLoading"
								@updateEnable="onDeleteTypeUpdated"
								id="booking" 
							/>
						</div>
						<div class="" v-if="booking && booking.artist">
							<span class="font-bold">Just remove {{booking.artist.name}}, and continue looking for others.</span> {{ booking.artist.name }} will be removed and notified. GigPig will revert this gig back to a pending state where you can choose from your previous artists, or add new&nbsp;ones.</div>
					</div>
					<div v-show="relistOnOff" class="flex items-center border-red">
						<div class="mr-8">
							<SwitchToggle 
								:enabled="relistGig"
								:disabled="isLoading"
								@updateEnable="onDeleteTypeUpdated"
								id="relist" 
							/>
						</div>
						<div>
							<span class="font-bold">Re-list this gig.</span>  All existing artists will be notified to give their availability again.</div>
					</div>
					<span v-show="!relistOnOff" class="text-red-600">Re-listing will become available 7 days prior to the gigs start date.</span>
				</div>
        <div v-if="cancelEntirely || removeArtist || relistGig" class="flex flex-col gap-4 sm:gap-8 my-6 sm:my-8">
        <div class="border-red">
          <div class="mr-8"></div>
          <div class="ml-14 pl-6">

            <div class="text-left sm:mt-5">
              <div class="text-sm" style="color: #000 !important;">
                <TRichSelect
                    v-model="selectedReason"
                    class="col-span-12 lg:col-span-5 cursor-pointer"
                    :close-on-select="true"
                    :options="cancellationReasons"
                    :hideSearchBox="true"
                    valueAttribute="id"
                    textAttribute="reason"
                    placeholder="Select a reason for cancellation"
                    @change="reasonSelected"
                >
                </TRichSelect>
              </div>
            </div>
            <TTextarea
                v-if="selectedReason && selectedReason === 6"
                v-model="other"
                class="col-span-12 lg:col-span-7 mt-6"
                placeholder="Other (please specify)"
            ></TTextarea>
            <p v-if="errorMessage" class="errors text-red-600 mt-3 text-sm">{{ errorMessage }}</p>

          </div>
        </div>
        </div>

			</template>
			<template v-else>
				<div class="mt-6 sm:mt-5 mb-4 col-span-full">
					Are you sure you want to cancel this gig? As no artists have been booked, this gig will no longer appear on artists&nbsp;calendars.
				</div>
			</template>
			

			<div class="flex">
				<div class="flex w-full items-end sm:items-center justify-center min-h-full shadow ring-1 ring-black ring-opacity-5 mx-3 mb-12 sm:mx-16 sm:mb-2 rounded sm:rounded-lg p-2 text-center transition-all" :class="classes">
					<p class="text-md text-red-600">
						Request completed, click 'Close' and refresh the page to see applied changes.
					</p>
				</div>
			</div>


		</template>

		<template #footer>

			<ModalFooterWrapper>
				<template #left>
					<Button @click.native="onClose" :disabled="isLoading" color="gray">Close</Button>
				</template>
				<template #right>

					<template v-if="!hasBookings">
						<Button @click.native="onDeleted()" :disabled="submitDisabled" :loading="isLoading">{{ cancelButtonLabel }}</Button>
					</template>
					<template v-else-if="relistGig">
						<Button  @click.native="onDeleted()"  :loading="isLoading  " color="indigo">
							Re-list and notify artists to provide fresh availability
						</Button> 
					</template>
					<template v-else-if="showCancelButton">
						<Button  @click.native="onDeleted()" :disabled="submitDisabled" :loading="isLoading && notifyArtist" color="indigo">
							{{ artistCancelledBooking ? 'Cancel the gig' : 'Cancel and notify the booked artist(s) for me'}}
						</Button> 
					</template>
					<template v-else>
						<div class="inline-flex px-4 py-1 items-center border border-transparent text-base font-medium justify-center text-center ">Select an option above to continue</div>
					</template>

				</template>
			</ModalFooterWrapper>
					
		</template>
	</ModalWrapper>
	
</template>

<script>
import ModalWrapper from '../../ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '../../ui/modal/ModalFooterWrapper.vue'
import Button from '../../ui/buttons/Button.vue'
import SwitchToggle from '../../ui/form/SwitchToggle.vue'
import formatDate from '../../../utils/format-date.js';
import {TRichSelect, TTextarea} from "vue-tailwind/dist/components";
import { apiMethods } from '@/state/helpers';
import client from "@/utils/client";

export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
		Button,
		SwitchToggle,
    TRichSelect,
    TTextarea
	},
	props:{
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
		gigId:{
			type:Number,
			required: true,
		},
		gigName:{
			type:String,
			required: true,
		},
		gigStart:{
			type:String,
			required: true,
		},
		booking:{
			type:Object,
			required: false
		},
		gig:{
			type:Object,
			required: true
		},
    artistCancelledBooking:{
      type:Boolean,
      required: false,
      default: false
    },
	},
	emits:["closed", "deleted", "reasonSelected"],

	data(){
		return {
			isLoading: false,
			cancelEntirely: false,
			removeArtist: false,
			notifyArtist: false,
			relistGig: false,
			error:false,
      cancellationReason: null,
      other: '',
      cancellationReasonId: null,
      selectedReason: null,
      errorMessage: 'Please give a reason for your cancellation',
      cancellationReasons: [],
		}
	},
	computed:{
    showCancelButton() {
      if ((this.cancelEntirely || this.removeArtist) && this.selectedReason && !this.errorMessage)
        return true

      return this.relistGig
    },
    cancellationText() {
      return this.artistCancelledBooking ? 'Cancel the gig entirely.'
          : 'Cancel the gig entirely and notify all booked artists.'
    },
		hasBookings(){
			return this.gig.bookings;
		},
		relistOnOff(){
			let date = new Date(this.gigStart);
			date.setDate(date.getDate() - 7);

			let gigStartDate = new Date(this.gigStart);
			gigStartDate.setDate(gigStartDate.getDate());
 
			return formatDate(new Date(), 'yyyy-MM-dd HH:mm') > formatDate(date, 'yyyy-MM-dd HH:mm') && formatDate(new Date(), 'yyyy-MM-dd HH:mm') < formatDate(gigStartDate, 'yyyy-MM-dd HH:mm');
		},
		introText(){
			return formatDate(this.gigStart, 'dd/MM/yy HH:mm').replaceAll(',', '');
		},
		modalTitle() {
      return `Cancelling ${ this.booking ? this.booking.artist.name + ' ' + ' from ' + this.gigName : this.gigName }`
    },
		// deleteOption() {
    //   return (id) => this.form.access.includes(fullAccessId) && this.form.access.length === 1 ? true : this.form.access.includes(id);
    // },
		cancelButtonLabel() {
      return this.isLoading ? 'Cancelling the gig' : 'Cancel the gig';
    },
		submitButtonLabel() {
      return this.isLoading ? 
				!this.cancelEntirely ? 'Cancelling the booking' : 'I will notify the artist' : 
				!this.cancelEntirely ? 'Cancel the booking' : 'I will notify the artist';
    },
		submitDisabled() {
      return this.isLoading;
    },
		classes() {
			return !this.error ? 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' : 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100'
		}
	},
	watch:{
		isOpen(){
			this.checkBooking(this.booking);
		},
		booking(updatedBooking){
			this.checkBooking(updatedBooking)
		},
    cancelEntirely() {
      this.selectedReason = null;
    },
    selectedReason() {
      if (this.selectedReason) {
        if (this.selectedReason === 6 && this.other === '') {
          this.errorMessage = 'Please give a reason for your cancellation'
        } else {
          this.errorMessage = false
        }
      } else {
        this.errorMessage = 'Please select a reason for your cancellation'
      }
    },
    other() {
      if (this.other && this.other.length && this.selectedReason === 6) {
        this.errorMessage = false
      } else {
        this.errorMessage = 'Please select a reason for your cancellation'
      }
    }
	},
	methods:{
    ...apiMethods,
    reasonSelected() {
      this.$emit('reasonSelected', this.selectedReason);
    },
		checkBooking(booking){
			if(!booking){
				this.cancelEntirely = true;
			}else{
				this.cancelEntirely = false
			}
		},
		onClose(){
			this.error = false;
			this.$emit("closed");
		},
		onDeleteTypeUpdated(id){
			this.error = false;
				console.log(id);
			if(id === 'all'){
				this.cancelEntirely = true;
				this.removeArtist = false;
				this.relistGig = false;
			}else if (id === 'relist'){
				this.cancelEntirely = false;
				this.removeArtist = false;
				this.relistGig = true;
			}else{
				this.cancelEntirely = false;
				this.removeArtist = true;
				this.relistGig = false;
			}
		},
		onDeleted(){
      if (!this.errorMessage || this.relistGig) {
        this.notifyArtist = true;
        this.isLoading = true;
        this.error = false;
        const payload = {
          cancel_entirely: this.cancelEntirely,
          remove_artist: this.removeArtist,
          notify_artist: this.notifyArtist,
          relist_gig: this.relistGig,
          artist_to_remove: (this.removeArtist && !this.cancelEntirely && this.booking) ? this.booking : null,
          reason_id: this.selectedReason,
          other: this.other
        };

        client
            .post(`gigs/${this.gigId}/cancellations`, payload)
            .then(() => {
              this.isLoading = false;
              this.$emit("deleted", payload);
            })
            .catch(err => {
              console.log(err)
              this.isLoading = false;
              this.error = true;
            })
      }
		},
	},
	mounted(){
    this.getGigCancellationReasons(['venue', 'venue_cancelled_artist', 'all']).then((resp) => {
      this.cancellationReasons = resp.data.data;
    });
		this.checkBooking(this.booking)
	}
}
</script>

