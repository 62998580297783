<template>
	<div>
		
		<p class="text-xs p-4"><span class="uppercase text-indigo-500">New</span> Your calendar will only show confirmed gigs, to see gigs you've responded to, see <router-link to="/artist/offers" class="text-gp_pink-default-500 uppercase">new gigs</router-link> and <router-link class="text-gp_pink-default-500 uppercase"  to="/artist/awaiting-venue-response">availability given</router-link>.</p>
		<!-- Calendar -->
		<CalendarWrapper
			calendar-type="ArtistGigs"
			:view-options="calendarViewOptions"
			:available-status-keys="calendarAvailableStatusKeys"
			:schedules="schedules"
      :weekStartDay="toggleWeekStartDay"
			@selected="scheduleSelected"
			:isReadOnly="true"
			@change="changeArtist()"
			id="calendarWrapper"
      @update="update"
      v-on:statusFiltered="filterByStatus($event)"
		>
			<template v-slot:filters>
				<!-- User Artists filter -->
				<TRichSelect 
					id="artist-selection" 
					ref="artistpicker" 
					name="artist-selection" 
					v-model="filteredArtists"
					placeholder="Filter by artist"                    
					:options="artists"
					value-attribute="id" 
					text-attribute="name" 
					class="w-full"
					@change="changeArtist()"
				/>
			</template>

      <template v-slot:weekStartDay>
        <div
            class="flex items-center justify-end pt-1 pb-4 col-span-full"
        >
                    <span
                        class="mr-4 text-sm md:text-md"
                        v-if="toggleWeekStartDay"
                    >Start week on Monday</span
                    >
          <span class="mr-4 text-sm md:text-md" v-else
          >Start week on Sunday</span
          >
          <SwitchToggle
              :enabled="toggleWeekStartDay"
              @updateEnable="onToggleWeekStartDay"
          />
        </div>
      </template>
		</CalendarWrapper>
		<!-- Schedule modal -->
		<calendar-preview 
			ref="calendarPreview" 
			:view-type="'ARTIST'"
			:bid-id="selectedBidId"
			:show-as-bid="true"
		/>

		<CalendarHover ref="calendarhover" id="tooltip"/>
	</div>

</template>

<style>
.tui-full-calendar-month-creation-guide,
.tui-full-calendar-weekday-grid-line{
	background-color: transparent !important;
	border: 1px solid rgba(17, 24, 39, 0.03) !important;
}

</style>

<script>
import NProgress from 'nprogress'
import { TRichSelect } from 'vue-tailwind/dist/components';
import CalendarWrapper from '@/components/calendar/CalendarWrapper.vue';
import CalendarPreview from '@/components/modal/CalendarPreview.vue';
import CalendarHover from '@/components/modal/CalendarHover.vue';
import { getStatusesFromTypes, getStatusFromType } from '@/state/models/bidStatus'
import normalizeDate from '@/utils/normalizeDate';
import { apiMethods } from '@/state/helpers.js';
import SwitchToggle from "@/components/ui/form/SwitchToggle.vue";
import client from "@/utils/client";
import {mapState} from "vuex";
const { DateTime } = require("luxon");

export default {
	components:{
    SwitchToggle,
		TRichSelect,
		CalendarWrapper,
		CalendarPreview,
		CalendarHover
	},
	data(){
		return{
			mobile: false,
			artistbids:null,
			artists:null,
			schedules:[],
			filteredBids:[],
      statusFilters:[
        'CANCELLED',
        'CONFIRMED',
        'AWAITING_SIGN_OFF',
        'SIGNED_OFF',
        'CANCELLATION_REQUESTED_BY_ARTIST'
      ],
			filteredArtists:null,
			isLoading:false,
			selectedBidId:null,
      dateRange: null,
			calendarViewOptions:[
				{ value: 'month', text: 'Month view'}, 
				{ value: 'week', text: 'Week view'},
				{ value: 'day', text: 'Day view'},
			],
			calendarAvailableStatusKeys: getStatusesFromTypes([
				'CANCELLED',
				'CONFIRMED',
				'AWAITING_SIGN_OFF',
				'SIGNED_OFF',
			]),
      toggleWeekStartDay: false,
		}
	},
	computed:{
    ...mapState({
      calendarStartDay: (state) => state.calendarStartDay,
    }),
		bankHolidays() {
			return this.$store.getters['bankHolidays/bankHolidays']
		},
	},
  watch: {
    dateRange() {
      this.load()
    },
    statusFilters() {
      this.parseBids();
    }
  },
	methods:{
		...apiMethods,
    filterByStatus(event)
    {
      this.statusFilters = event;
    },
    initialDate() {
      const date = new Date()

      return {
        date_from: new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substring(0, 10),
        date_to: new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().substring(0, 10)
      }
    },
    update(val) {
      const date = new Date(val.currentDate)

      this.dateRange = {
        date_from: new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substring(0, 10),
        date_to: new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().substring(0, 10)
      }
    },
    load() {
      this.isLoading = true;
      NProgress.start();
      Promise.all([
        // Artists
        this.$store.dispatch('artists/getArtists')
            .then((response) => {
              this.artists = [
                { name:"All artists", id:"all" },
                ...response
              ]
            }),
        // Artist Bids
        this.$store.dispatch('artistBids/loadArtistBids', {date_from: this.dateRange.date_from, date_to: this.dateRange.date_to})
            .then((response) => {
              this.artistbids = response;
              this.parseBids()
            })
      ]).finally(() => {
        this.isLoading = false;
        NProgress.done();
      });

      this.$store.dispatch('bankHolidays/getBankHolidays')

      var query = window.matchMedia("(max-width:768px)");

      if (query.matches){
        this.mobile = true;
      } else {
        this.mobile = false;
      }

      if (!this.mobile){

        const tooltip = document.querySelector('#tooltip');

        const calendarWrapper = document.getElementById('calendarWrapper');
        calendarWrapper.addEventListener('mouseover', (e) => {
          const el = e.target;
          const schedule = el.closest('.tui-full-calendar-weekday-schedule');


          if (schedule) {

            let scheduleBidID = schedule.getAttribute('data-schedule-id');

            let gigObj = this.schedules.find(gig => {

              if (!Object.hasOwn(gig, 'raw')) {
                return false
              }

              return gig.raw.id === parseInt(scheduleBidID)
            });

            if (gigObj !== undefined) {

              const obj =  { ...gigObj.raw.gig, status: gigObj.raw.status }

              this.$refs.calendarhover.isArtistView = true;
              this.$refs.calendarhover.item = obj;
              this.$refs.calendarhover.artistBid = gigObj.raw;

              this.getArtist(gigObj.raw.artist.id)
                  .then((resp) => {
                    if (resp.image !== null) {
                      this.$refs.calendarhover.bookedArtist = resp.image.url;
                    }
                  });

              this.$refs.calendarhover.tooltip = true;
            }

          } else {
            this.$refs.calendarhover.tooltip = false;
          }

          Object.assign(tooltip.style, {
            top: `${event.pageY - 220}px`,
            left: `${event.pageX - 260}px`,
          });

          if (event.pageY < 400){
            Object.assign(tooltip.style, {
              top: `${event.pageY}px`,
            });
          } if (event.pageY > 750){
            Object.assign(tooltip.style, {
              top: `${event.pageY - 220}px`,
            });
          } if (event.pageX > 840){
            Object.assign(tooltip.style, {
              left: `${event.pageX - 680}px`
            });
          } if (event.pageX < 494){
            Object.assign(tooltip.style, {
              left: `${event.pageX - 260}px`
            });
          }
        });
      }
    },
		changeArtist(){
			this.setCalendarItems();
		},
		parseBidToSchedule(bid){
			const bidStatus = getStatusFromType(bid.status)
			let end = bid.gig.end;
			
			
			let startHour = DateTime.fromISO(bid.gig.start).toUTC().hour;
			let endHour = DateTime.fromISO(bid.gig.end).toUTC().hour;

			if (endHour < startHour) {
				end = DateTime.fromISO(bid.gig.start).toUTC().set({hours: 23, minutes: 59});
			}
			return {
				id: bid.id,
				title: bidStatus.name,//bid.gig.name,
				category: 'time',
				start: normalizeDate(bid.gig.start),
				end: normalizeDate(end),
				calendarId: 0,
				raw: bid,
				bgColor: bidStatus.bg,
				color: bidStatus.color,
				customStyle: `background-color:${ bidStatus.bg }; color:${ bidStatus.color }!important;`,
				status: bidStatus.name,
			}
		},
		parseBids(){
			this.filteredBids = this.artistbids
				.filter((ab) => {
					//  ab.status !== 'UNCAUGHT_STATUS' && ab.status !== 'DECLINED'
					return ab.gig && ab.status && this.statusFilters.filter(i => i === ab.status).length > 0
				})
				.map((fab) => {
					return this.parseBidToSchedule(fab)
				})

			this.setCalendarItems()
		},

		setCalendarItems(){
			let schedules = [ ...this.filteredBids ]
			// check for filter
			if(this.filteredArtists && this.filteredArtists !== "all"){ 
				schedules = schedules.filter(s => s.raw.artist.id === this.filteredArtists);
			}

				const bankHolidays = [...this.bankHolidays]
					.map((bankHoliday) => {
							return {
									title: bankHoliday.region === 'England and Wales' ? bankHoliday.title : `${bankHoliday.title} (${bankHoliday.region})`,
									category: 'time',
									start: bankHoliday.date,
									calendarId: 0,
									bgColor: '#E0BBE4',
									color: '#E0BBE4',
									customStyle: 'background-color:' + '#E0BBE4'
							}
					})

				this.schedules = [...schedules, ...bankHolidays];
 
		},

		scheduleSelected(schedule){
			const selectedBid = this.artistbids.filter(ab => ab.id === schedule.id)[0];
			if(selectedBid){
				this.selectedBidId = selectedBid.id;
				let preview = this.$refs.calendarPreview;
				const obj =  { ...selectedBid.gig, status: selectedBid.status }

				this.getArtist(schedule.raw.artist.id)
					.then((resp) => {
						preview.bookedArtist = resp.image.url;
					});

				preview.artistBid = schedule.raw;
				preview.item = obj;
				this.$refs.calendarhover.tooltip = false;
				preview.viewModal = true;
			}			
		},
    onToggleWeekStartDay() {
      this.toggleWeekStartDay = !this.toggleWeekStartDay;
      this.$store.dispatch(
          "setCalendarStartDay",
          this.toggleWeekStartDay
      );
      this.setWeekStartDay();
    },
    setWeekStartDay() {
      client.put("user/update-calendar-start/", {
        state: this.toggleWeekStartDay,
      }).then(() => {
        this.$emit("weekStartDayChanged", this.toggleWeekStartDay);
      });
    },
	},

	mounted(){
    this.toggleWeekStartDay = !!this.calendarStartDay;
    this.dateRange = this.initialDate()
	}
}
</script>
