<template>
    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <SubmitMITPayment
            ref="paymentModal"
            :show-modal="showMITModal"
            :MITPublicKey="pubKey"
            :MITClientSecret="MITClientSecret"
            :customerEmail="ryftCustomerEmail"
            @showMITPaymentModal="value => showMITModal = value"
            @formSubmitted="formSubmitted"
            @cardSubmissionComplete="cardSubmissionComplete"
            @cardNumberIsValid="value => cardNumberIsValid = value"
        >
        </SubmitMITPayment>
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-[#0D1C43] border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                    <div class="flex justify-end mb-6 text-gp_pink-default-500">
                        <XIcon @click="toggleModal" class="hover:text-gp_pink-default-400 cursor-pointer"/>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
                        <div v-show="step == 1">
                            <!-- LEFT COLUMN -->
                            <div class="h-auto w-full">
                                <div>
                                    <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Your Top up options</h3>
                                    <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                                </div>

                                <div class="mt-6">
                                    <div class="grid grid-cols-2">
                                        <div>
                                            <p class="text-lg mt-2 text-gray-100">Credits to top-up</p> 
                                            <p class="uppercase text-xs mt-1 text-gray-100 font-medium">1 booking = 1 credit</p> 
                                        </div>
                                        <div class="flex items-center justify-center mt-2">
                                            <div class="w-1/2 py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-neutral-900 dark:border-neutral-700" data-hs-input-number="">
                                                <div class="flex justify-between items-center gap-x-5">
                                                    <div class="grow">
                                                        <input 
                                                            v-model="cred_qty"
                                                            class="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0" 
                                                            style="appearance: textfield; -moz-appearance: textfield; -webkit-appearance: textfield; position: relative;"
                                                            type="number" 
                                                            aria-roledescription="Number field" 
                                                            min="0"
                                                            value="1" 
                                                            data-hs-input-number-input=""
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid grid-cols-4 mt-12 w-auto">
                                        <div class="space-y-8 col-span-3">
                                            <p class="text-md mt-2 text-gray-200 font-light">Credits</p> 
                                            <p class="text-md mt-2 text-gray-200 font-light">+VAT @ 20%</p> 
                                            <p class="text-md mt-2 text-gray-200 font-light">Amount to Pay</p> 
                                        </div>
                                        <div v-show="this.cred_qty > 0" class="space-y-8 place-items-end">
                                            <p class="text-md mt-2 text-gray-200 font-light">£{{ creditsTotal }}</p> 
                                            <p class="text-md mt-2 text-gray-200 font-light">£{{ vatTotal }}</p> 
                                            <p class="text-md mt-2 text-gray-200 font-light">£{{ totalFinal }}</p> 
                                        </div>
                                        <div v-show="this.cred_qty <= 0" class="space-y-8 place-items-end">
                                            <p class="text-md mt-2 text-gray-200 font-light">N/A</p> 
                                            <p class="text-md mt-2 text-gray-200 font-light">N/A</p> 
                                            <p class="text-md mt-2 text-gray-200 font-light">N/A</p> 
                                        </div>
                                    </div>
                                </div>

                                <div v-show="paymentCards.length == 0" class="w-full mt-10">
                                    <GPButton @click.native="addBillingAddress()"
                                                    color="pink"
                                                    class="w-full justify-between"
                                    >
                                        <span class="font-semibold">Add billing address</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </GPButton> 
                                </div>

                                <div v-show="paymentCards.length > 0" class="w-full mt-10">
                                    <GPButton @click.native="chooseCard()"
                                                    color="pink"
                                                    class="w-full justify-between"
                                    >
                                        <span class="font-semibold">Pay now</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </GPButton> 
                                </div>

                                <div class="space-y-2 mt-10">
                                    <p class="text-xs text-gray-200">Want to be billed monthly and book months in advance?</p>
                                    <p @click="openSubscriptionsModal()" class="underline text-xs text-gray-200 hover:text-gray-100 font-bold cursor-pointer">View our subscription options</p>
                                </div>

                            </div>
                        </div>

                        <div v-show="step == 2">
                            <!-- LEFT COLUMN -->
                            <div class="h-auto w-full">
                                <div>
                                    <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">BILLING ADDRESS</h3>
                                    <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                                </div>

                                <div class="mt-6">
                                    <div class="flex items-center justify- space-x-3 pt-8 text-gray-300 text-sm font-semibold">
                                        <span>Same as venue address</span>
                                        <t-toggle @click="same_as_address = !same_as_address;"/>
                                    </div>
                                    
                                    <div v-show="!same_as_address">
                                        <div class="pt-4 space-y-1 text-sm font-semibold">
                                            <span class="text-gray-100 text-sm font-light">Billing address line one</span>
                                            <input type="text" 
                                                    v-model="billing_address.address_line_1"
                                                    placeholder="Address line one"
                                                    id="billing_address_line_1" 
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                    required 
                                            />
                                        </div>
                                        <div class="pt-4 space-y-1 text-sm font-semibold">
                                            <span class="text-gray-100 text-sm font-light">Billing address line two (Optional)</span>
                                            <input type="text" 
                                                    v-model="billing_address.address_line_2"
                                                    placeholder="Address line two"
                                                    id="billing_address_line_2" 
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                            />
                                        </div>
                                        <div class="pt-4 space-y-1 text-sm font-semibold">
                                            <span class="text-gray-100 text-sm font-light">Billing address city</span>
                                            <input type="text" 
                                                    v-model="billing_address.city"
                                                    placeholder="Address city"
                                                    id="billing_address_town" 
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                    required 
                                            />
                                        </div>
                                        <div class="pt-4 space-y-1 text-sm">
                                            <span class="text-gray-100 text-sm font-light">Billing address postcode</span>
                                            <input type="text" 
                                                    v-model="billing_address.post_code"
                                                    placeholder="Address postcode"
                                                    id="billing_address_post_code" 
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                    required 
                                            />
                                        </div>
                                        <div class="pt-4 space-y-1 text-sm font-semibold">
                                            <span class="text-gray-100 text-sm font-light">Billing address country</span>
                                            <input type="text" 
                                                    v-model="billing_address.country"
                                                    placeholder="Address country"
                                                    id="billing_address_county" 
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                    required 
                                            />
                                        </div>
                                    </div>
                                    <div class="pt-4 space-y-1 text-sm font-semibold w-full">
                                        <span class="text-gray-100 text-sm font-light">Currency</span>
                                        <TRichSelect
                                            id="currencies"
                                            name="currencies"
                                            v-model="currency"
                                            :options="currencies"
                                            @change="pickCurrency"
                                            value-attribute="id"
                                            text-attribute="display_name"
                                            class="w-full block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-lg"
                                        />
                                    </div>
                                </div>

                                <div class="w-full mt-10">
                                    <GPButton @click.native="addCardDetails()"
                                                    :disabled="isSubmitting"
                                                    :loading="isSubmitting"
                                                    color="pink"
                                                    class="w-full justify-between"
                                    >
                                        <span class="font-semibold">Add card details</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </GPButton> 
                                </div>
                            </div>
                        </div>

                        <div v-show="step == 3">
                            <!-- LEFT COLUMN -->
                            <div class="h-auto w-full">
                                <div>
                                    <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Save a card</h3>
                                    <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                                </div>
                                <div class="mt-6">
                                    <div>
                                        <div class="pt-4 space-y-1 text-sm font-semibold">
                                            <span class="text-gray-100 text-sm font-light">Card nickname</span>
                                            <input type="text" 
                                                    v-model="billing_address.card_nickname"
                                                    placeholder="e.g. Head office card"
                                                    id="billing_address_line_1" 
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                    required 
                                            />
                                        </div>
                                        <p class="text-xs text-gray-200 mt-2 w-full font-light">Naming your card will help distinguish different cards used by your business.</p>
                                    </div>
                                    <div class="mt-6">
                                        <span class="text-gray-100 text-sm font-light">Recipient email for this transaction receipt</span>
                                        <input type="text" 
                                                    v-model="billing_address.recipient_email"
                                                    placeholder="accounts@myvenue.com"
                                                    id="billing_address_line_1" 
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-2" 
                                                    required 
                                            />
                                    </div>
                                    <div class="grid grid-cols-5 mt-6">
                                        <div class="col-span-1 mt-4">
                                            <t-toggle @click="confirm_charge = !confirm_charge;"/>
                                        </div>
                                        <div class="col-span-4">
                                            <span class="text-gray-200 text-xs text-justify font-light">I confirm my card will be charged by GigPig Ltd for the purpose of token purchases by those with access to my venue.</span>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <span class="text-gray-200 text-xs text-justify font-light">
                                            Your card details will be securly saved for future use by for your venues booking team, so they don’t need access to your card beyond this point.
                                        </span>
                                    </div>
                                </div>

                                <div class="w-full mt-10">
                                    <GPButton @click.native="addCardDetailsFinal()"
                                                    :disabled="isSubmitting"
                                                    :loading="isSubmitting"
                                                    color="pink"
                                                    class="w-full justify-between"
                                    >
                                        <span class="font-semibold">Add card details</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </GPButton> 
                                </div>
                            </div>
                        </div>

                        <div v-show="step == 4">
                            <!-- LEFT COLUMN -->
                            <div class="h-auto w-full">
                                <div>
                                    <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Choose payment card</h3>
                                    <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                                </div>

                                <div class="mt-6">
                                    <span class="text-gray-100 text-sm font-light">Choose payment card</span>
                                    <TRichSelect
                                        id="currencies"
                                        name="currencies"
                                        v-model="chosenPaymentCard"
                                        :options="paymentCards"
                                        value-attribute="id"
                                        text-attribute="alias"
                                        class="mt-2 w-full block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-lg"
                                    >
                                        <template slot="option" slot-scope="{ index, isHighlighted, isSelected, className, option }">                                 
                                            <div class="flex py-2 ml-3">
                                                <component :is="paymentCards[option]" class="ml-3"/>  
                                                <p class="font-bold">{{ option.raw.alias }} </p>
                                            </div>
                                            <div class="flex py-1 ml-3 hover:border-b text-xs">
                                                <component :is="paymentCards[option]" class="ml-3"/>  
                                                <div class="flex items-center justify-between gap-x-8">
                                                    <div>
                                                        <p class="text-xs">Expires {{ option.raw.expiry_month }}/{{ option.raw.expiry_year }}</p>
                                                    </div>
                                                    <div>
                                                        <p class="text-xs font-bold">**** {{ option.raw.last_4_digits }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </TRichSelect>

                                    <div class="my-12">
                                        <span @click="addNewCard()" class="underline text-xs text-gp_pink-default-500 hover:text-gp_pink-default-400 font-bold cursor-pointer">Add a new payment card</span>
                                    </div>
                                </div>

                                <div class="w-full mt-10">
                                    <GPButton @click.native="confirmCard()"
                                                    :disabled="isSubmitting"
                                                    :loading="isSubmitting"
                                                    color="pink"
                                                    class="w-full justify-between"
                                    >
                                        <span class="font-semibold">Confirm payment card</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </GPButton> 
                                </div>
                            </div>
                        </div>

                        <div v-show="step == 5">
                            <!-- LEFT COLUMN -->
                            <div class="h-auto w-full">
                                <div>
                                    <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Confirm Purchase</h3>
                                    <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                                </div>

                                <div class="mt-6">
                                    
                                    <div class="space-y-3">
                                        <p class="block font-bold text-gray-100">Using {{ this.billing_address.card_nickname }} card</p>
                                        <p class="block font-bold text-gray-100">Purchasing {{ cred_qty }} credit<span v-show="cred_qty > 1">s</span></p>
                                    </div>

                                    <div class="grid grid-cols-4 mt-8">
                                        <div class="space-y-8 col-span-3">
                                            <p class="text-md mt-2 text-gray-200 font-light">Credits</p> 
                                            <p class="text-md mt-2 text-gray-200 font-light">+VAT @ 20%</p> 
                                            <p class="text-md mt-2 text-gray-200 font-light">Amount to Pay</p> 
                                        </div>
                                        <div class="space-y-8 place-items-end">
                                            <p class="text-md mt-2 text-gray-200 font-light">£{{ creditsTotal }}</p> 
                                            <p class="text-md mt-2 text-gray-200 font-light">£{{ vatTotal }}</p> 
                                            <p class="text-md mt-2 text-gray-200 font-light">£{{ totalFinal }}</p> 
                                        </div>
                                    </div>
                                </div>

                                <div class="w-full mt-10">
                                    <GPButton @click.native="payNow()"
                                                    :disabled="isSubmitting"
                                                    :loading="isSubmitting"
                                                    color="pink"
                                                    class="w-full justify-between"
                                    >
                                        <span class="font-semibold">Pay now</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </GPButton> 
                                </div>
                            </div>
                        </div>

                        <div v-show="step == 6">
                            <!-- LEFT COLUMN -->
                            <div class="h-auto w-full">
                                <div>
                                    <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Purchase Complete</h3>
                                    <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                                </div>

                                <div class="mt-6">
                                    <div class="space-y-3">
                                        <p class="text-gray-100 text-sm">A receipt invoice has been sent to your billing email.</p>
                                    </div>
                                </div>

                                <div class="w-full mt-24">
                                    <div @click="returnToPlatform()" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-lg px-6 text-white">
                                        <span class="font-semibold w-11/12">Return to platform</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="w-full">
                            <h3 class="block font-bold text-gp_pink-default-500 uppercase mb-12" id="modal-title">FREQUENTLY ASKED QUESTIONS</h3> 
                            <div :class="faqStyling">
                                <div v-for="(info, index) in faqs" 
                                    :key="'info' + index" 
                                    >
                                    <div class="mt-6">
                                        <div :class="{
                                                    'cursor-pointer flex items-center space-x-3 text-gray-100 hover:text-gp_pink-default-500': tableIndex !== index,
                                                    'cursor-pointer flex items-center space-x-3 text-gp_pink-default-500': tableIndex == index
                                                }"
                                            >
                                            <div>
                                                <ArrowCircleRightIcon @click="tableIndex = (tableIndex === index ? null : index)" v-if="tableIndex !== index" class="w-4 h-4"/>
                                                <ArrowCircleDownIcon v-if="tableIndex === index" class="w-4 h-4"/>
                                            </div>
                                            <span @click="tableIndex = (tableIndex === index ? null : index)" class="uppercase font-semibold text-xs">{{ info.question }}</span>
                                        </div>
                                        <div v-if="tableIndex === index" class="my-2 ml-2 p-4 rounded bg-[#0D1C43] mr-4 text-justify">
                                            <span class="text-gray-300 text-xs">{{ info.answer }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>        
        </div>
        <SubscriptionUpgrade ref="subupgrademodal" @closePAYGModal="returnToPlatform()" />
        <NotificationSmall ref="notification" />
    </div>
</template>

<script>
import { ArrowNarrowRightIcon, ArrowCircleRightIcon, ArrowCircleDownIcon, XIcon } from "@vue-hero-icons/outline"
import { TToggle, TRichSelect } from 'vue-tailwind/dist/components'
import SubscriptionUpgrade from '../../../../components/modal/venues/subscriptions/SubscriptionUpgrade.vue'
import {paymentsMethods, apiMethods} from "@/state/helpers";
import SubmitMITPayment from "@/components/MIT/SubmitMITPayment.vue";
import { RyftPublicKey } from "../../../../../auth_config.json"
import NProgress from 'nprogress';
import GPButton from '../../../../components/ui/buttons/Button.vue';
import client from "../../../../utils/client"
import { DEFAULT_CURRENCY_ID } from "@/constants/contants";
import NotificationSmall from '../../../../components/notifications/small.vue';

export default
{
    name: "TopUpOptions", 
    data() {
        return {
            modal_open: false,
            step: 1,
            tableIndex: null,
            cred_qty: 1,
            paymentCards: [],
            chosenPaymentCard: null,
            billing_address: {
                address_line_1: '',
                address_line_2: '',
                city: '',
                country: '',
                post_code: '',
                company_name: '',
                card_nickname: '',
                recipient_email: '',
            },
            venue: [],
            payment_log_id: null,
            currency: DEFAULT_CURRENCY_ID,
            base_currency: DEFAULT_CURRENCY_ID,
            currencies: [],
            cardId: null,
            confirm_charge: false,
            same_as_address: false,
            faqs: [],
            ryftCustomerCreated: false,
            showMITModal: false,
            MITClientSecret: '',
            pubKey: RyftPublicKey,
            ryftCustomerEmail: null,
            ryftOnboardingComplete: false,
            cardNumberIsValid: false,
            cardButtonDisabled: false,
            isSubmitting: false
        }
    },
    components: {
        ArrowNarrowRightIcon, ArrowCircleRightIcon, ArrowCircleDownIcon, XIcon,
        TToggle, TRichSelect,
        SubscriptionUpgrade,
        SubmitMITPayment,
        GPButton,
        NotificationSmall
    },
    computed: {
        creditsTotal() {
            return this.cred_qty * 10
        },
        vatTotal() {
            return (this.cred_qty * 10) * 0.2
        },
        totalFinal() {
            return (this.cred_qty * 10) + ((this.cred_qty * 10) * 0.2)
        },
        currencyIsoCode() {
            return this.currencies.find(f => f.id === this.base_currency).iso_code;
        },
        faqStyling() {
            return this.step != 2 ? 'h-96 overflow-y-auto' : this.same_as_address ? 'h-80 overflow-y-auto' : 'h-full overflow-y-auto';
        },
    },
    watch: {
        ryftCustomerCreated() {
            this.initRyftOnboarding();
        },
        async modal_open(value) {
            if (value) {
                const token = await this.$auth.getTokenSilently();
                client.get("currencies", { headers: { Authorization: `Bearer ${token}` } }).then(response => (this.currencies = response.data.data));
            }
        }
    },
    methods: {
        ...paymentsMethods,
        ...apiMethods,
        formSubmitted() {
            NProgress.start();
        },
        pickCurrency(value) {
            this.currency = value
        },
        chooseCard() {
            this.step = 4;
        },
        confirmCard() {

            this.isSubmitting = true;

            if (!this.chosenPaymentCard) {
                this.$notify("Please choose a valid payment card.");
                this.isSubmitting = false;
                return;
            }

            let card = this.paymentCards.find((f) => f.id === this.chosenPaymentCard);

            this.cardId = card.id;
            this.billing_address.card_nickname = card.alias;
            this.isSubmitting = false;
            this.step = 5;

        },
        addNewCard() {
            this.step = 2;
        },
        async openWithVenueInfo(venue_info) {

            this.venue = venue_info;

            if (venue_info.currency_id) {
                this.currency = venue_info.currency_id;
            }

            await this.getVenuePaymentCards({ venue_id: venue_info.id })
                .then((response) => {
                    this.paymentCards = response;
                })
                .finally(() => {
                    this.toggleModal();
                })
        },
        loadRyftSdk() {

            this.isSubmitting = true;
            this.cardButtonDisabled = true;

            this.createPaymentCard({
                default: 1, 
                email: this.billing_address.recipient_email,
                venue_id: this.venue.id,
                alias: this.billing_address.card_nickname,
            }).then(resp => {
                this.cardId = resp.data.id;
                this.ryftCustomerCreated = true;
            }).catch(() => {
                this.$notify("An error has occured, please contact GigPig support.");
                this.isSubmitting = false;
            })
        },
        initRyftOnboarding() {
            this.createMerchantPaymentSession(
                {
                amount: 0,
                verifyAccount: true,
                currency: this.currencyIsoCode,
                email: this.billing_address.recipient_email,
                metadata: {verifyAccount: true, default: true, cardId: this.cardId.toString(), venueId:this.venue.id },
                paymentType: 'Unscheduled',
                venue_id: this.venue.id,
                venueId:this.venue.id
                // ryft_customer_id: this.form.ryft_customer_id
                }
            ).then((resp) => {
                this.MITClientSecret = resp.data.clientSecret
                this.ryftCustomerEmail = resp.data.customerEmail
                this.showMITModal = true
                this.isSubmitting = false;
            })
            .catch(() => {
                this.$notify("An error has occurred. Please try again or contact support for further assistance.");
                this.isSubmitting = false;
            });
        },
        cardSubmissionComplete() {
            this.$notify("Please wait while we verify your payment card information.");
            this.cardSubmissionCheck();
        },
        cardSubmissionCheck(){
            setTimeout(() => {
                this.getVenuePaymentCard({ card_id: this.cardId })
                    .then((response) => {
                        if (!response.data.merchant_id || !response.data.initial_payment_id) {
                            this.cardSubmissionCheck();
                        } else {
                            this.step = 5;
                            this.ryftOnboardingComplete = true;
                            this.showMITModal = false;
                            NProgress.done();
                        }
                    })
                    .catch(() => {
                        this.$notify("An error has occurred. Please try again or contact support for further assistance.");
                        this.isSubmitting = false;
                    })
            }, 2000);
        },
        async toggleModal() {

            if (!this.isSubmitting) {
                if (!this.faqs.length) {
                    await this.getFaqs('payments').then((response) => { this.faqs = response.data; });
                }

                this.step = 1;
                this.modal_open = !this.modal_open;
            } else {
                this.$notify("Please await submission completion.");
            }

        },
        openSubscriptionsModal() {
            this.$refs.subupgrademodal.openWithVenueInfo({ id: this.venue.id , name: this.venue.name , currency_id: this.currency });
        },
        addBillingAddress() {

            if (!this.cred_qty || this.cred_qty < 1) {
                this.$notify("Please input a credit amount.");
                return;
            }

            this.step = 2;
        },
        async addCardDetails() {

            this.isSubmitting = true;

            if (!this.same_as_address && (!this.billing_address.address_line_1  || !this.billing_address.city || !this.billing_address.country || 
                !this.billing_address.post_code)) 
            {
                this.$notify("Please provide all requested details.");
                this.isSubmitting = false;
                return;
            }

            let params = {
                same_as_address: this.same_as_address,
                billing_address: {
                    address_line_1: this.billing_address.address_line_1,
                    address_line_2: this.billing_address.address_line_2,
                    town: this.billing_address.city,
                    country: this.billing_address.country,
                    post_code: this.billing_address.post_code,
                },
                currency: this.currency,
                venue_id: this.venue.id
            };

            await this.updateVenueBillingDetails(params)
                .then(() => {
                    this.isSubmitting = false;
                    this.step = 3;
                })

        },
        addCardDetailsFinal() {

            if (!this.billing_address.card_nickname  || !this.billing_address.recipient_email)
            {
                this.$notify("Please provide all requested details.");
                return;
            }

            if (!this.confirm_charge)
            {
                this.$notify("Please accept the terms of use to continue.");
                return;
            }

            this.loadRyftSdk()
        },
        tokenPaidCheck() {

            NProgress.start();

            setTimeout(() => {
                this.checkTokensFullyPaid({ payment_log_id: this.payment_log_id })
                    .then((response) => {
                        if (response.data.length !== parseInt(this.cred_qty)) {
                            this.tokenPaidCheck();
                        } else {
                            this.$notify("Payment successful.");
                            NProgress.done();
                            this.isSubmitting = false;
                            this.step = 6;
                        }
                    })
                    .catch(() => {
                        this.$notify("An error has occurred. Please try again or contact support for further assistance.");
                        this.isSubmitting = false;
                    })
            }, 2000);
        },
        payNow() {

            this.isSubmitting = true;

            let params = {
                payment_amount: parseFloat(this.totalFinal).toFixed(2),
                token_amount: parseInt(this.cred_qty),
                venue_id: this.venue.id,
                card_id: this.cardId
            }

            this.addPayg(params)
                .then((response) => {
                    this.payment_log_id = response.payment_log_id;
                    this.tokenPaidCheck();
                })
                .catch(() => {
                    this.$notify("An error has occurred. Please try again or contact support for further assistance.");
                    this.isSubmitting = false;
                })
                
        },
        async returnToPlatform() {
            await this.getUserInfo();
            this.toggleModal();
            if (sessionStorage.getItem('selected_venue') != null) {
                sessionStorage.removeItem('selected_venue')
            }
            window.location.reload();
        },
    }
}

</script>
