<template>
    <div v-if="!acc_info.has_active_legacy_subscription && !acc_info.has_active_venue_subscription && !acc_info.has_active_free_trial && !acc_info.has_active_payg_tokens" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-[#0D1C43] border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div>
                        <div class="mx-auto grid grid-flow-row gap-6 px-6 md:px-12 justify-items-center text-center">
                            <h3 class="block font-bold text-gp_pink-default-500 uppercase mt-6" id="modal-title">Feature Locked</h3> 
                            <p class="text-gray-100 font-bold">
                                Click here to top-up your venue with credits to enable the full GigPig experience.
                            </p>

                            <div class="flex flex-wrap md:gap-8 justify-center items-center">
                                <div @click="$router.push('/gigs/add')" class="bg-gray-100 hover:bg-gray-200 cursor-pointer my-2 md:my-6 py-2 px-6 rounded-lg flex space-x-2">
                                    <TicketIcon stroke="black"/>
                                    <span class="text-black">Book a gig</span>
                                </div>
                                <div @click="$router.push('/venues')" class="bg-gp_pink-default-500 hover:bg-gp_pink-default-400 cursor-pointer my-2 md:my-6 py-2 px-6 rounded-lg flex space-x-2">
                                    <DatabaseIcon stroke="white"/>
                                    <span class="text-white">Add credits now</span>
                                </div>
                            </div>

                        </div>
                    </div>

                   
                </div> 
            </transition>        
        </div>
    </div>
</template>

<script>
import { DatabaseIcon, TicketIcon } from "@vue-hero-icons/outline"

export default
{
    name: "FeatureLocked", 
    data() {
        return {
            acc_info: JSON.parse(localStorage.getItem('acc_info'))
        }
    },
    components: {
        DatabaseIcon, TicketIcon,
    }
}

</script>