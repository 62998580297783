<template>
    <div class="min-h-1100px flex flex-col justify-between pt-2 px-4" v-if="invoiceItem">

        <div class="grid grid-flow-row gap-8">
            <div class="grid grid-flow-row gap-4">
                <span class="text-4xl">Invoice</span>
                <div>
                    <div v-if="invoiceItem.mit_payment_id">Ref: {{ invoiceItem.mit_payment_id }}</div>
                    <div>VAT Number: 413694102</div>
                </div>
            </div>
          <div class="flex justify-between">
              <div>
                    <ul>
                        <li>GigPig Ltd</li>
                        <li>First Floor</li>
                        <li>Bridge Street Studios</li>
                        <li>62 Bridge St</li>
                        <li>Manchester</li>
                        <li>M3 3BW</li>
                    </ul>
              </div>
          </div>
          <div class="grid grid-flow-row">
              <div class="font-bold uppercase text-sm">
                  Invoice to:
              </div>
                <ul>
                    <li>{{ venue.name }}</li>
                    <li v-if="venue && venue.billing_address && venue.billing_address.line_1">{{ venue.billing_address.line_1 }}</li>
                    <li v-if="venue && venue.billing_address && venue.billing_address.line_2">{{ venue.billing_address.line_2 }}</li>
                    <li v-if="venue && venue.billing_address && venue.billing_address.town">{{ venue.billing_address.town }}</li>
                    <li v-if="venue && venue.billing_address && venue.billing_address.county">{{ venue.billing_address.county }}</li>
                    <li v-if="venue && venue.billing_address && venue.billing_address.post_code">{{ venue.billing_address.post_code }}</li>
                </ul>
          </div>
          <div class="grid grid-flow-row w-full gap-4">
  
              <div class="uppercase font-bold text-sm">Item description</div>
              <section class="grid grid-flow-row w-full gap-4">
                  <div class="flex flex-col  justify-between">
                      <div v-if="invoiceItem.token_quantity">{{ invoiceItem.token_quantity }} x Gig credits</div>
                      <div class="uppercase font-bold text-sm mt-4">Purchased</div>
                      <div v-if="invoiceItem.created_at" class="mt-4">{{ dateFormat(invoiceItem.created_at) }}</div>
                  </div>
                  <div class="mt-4 flex flex-row justify-between  border-solid border-t-2 border-gray-500">
                      <div class="uppercase font-bold text-sm">Credits</div>
                      <div v-if="invoiceItem.token_quantity && invoiceItem.amount !== '0.00'" class="font-bold">£{{ creditCost }}</div>
                  </div>
              </section>
              <section  class="grid grid-flow-row w-full gap-4">
                  <div class="flex flex-row justify-between">
                      <div>VAT @ 20%</div>
                      <div v-if="invoiceItem.token_quantity && invoiceItem.amount !== '0.00'" >£{{ calculateVAT }}</div>
                  </div>    
                  <div class="flex flex-row justify-between  border-solid border-t-2 border-gray-500">
                      <div class="uppercase font-bold text-sm">Total</div>
                      <div v-if="invoiceItem.token_quantity && invoiceItem.amount !== '0.00'" class="font-bold">
                        £{{ totalCost }}</div>
                  </div>      
              </section>
          </div>
      </div>
      
      <div class="text-center  flex flex-row justify-between items-center">
          
          <a href="https://gigpig.uk">
              <img src="@/assets/images/poweredby_pdf.jpg" alt="Powered by Gig Pig" class="h-12 w-24"/>
          </a>
          <!-- <div class="text-right">
              Ref: {{ invoiceItem.mit_payment_id }}
          </div> -->
      </div> 
  </div>
  </template>
  
<script>  

export default
{
    name: "InvoiceContents",
    computed: {
        venue() {
            return this.$store.getters['venue/currentVenue']
        },
        creditCost() {
            return parseFloat(this.invoiceItem.amount).toFixed(2)
        },
        calculateVAT() {
            return parseFloat(20 * (this.creditCost / 100)).toFixed(2)
        },
        totalCost() {
            return (parseFloat(this.creditCost) + parseFloat(this.calculateVAT)).toFixed(2);
        },
    },    
    methods: {
        dateFormat(sub_date){   
            const date = new Date(sub_date.replace(" ", "T"));
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
    },
    props:{
        invoiceItem: Object, 
    }
}
</script>